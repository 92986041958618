import { Link } from "react-router-dom";
import Header from "../../components/Header";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Footer from "../../components/Footer";

const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
        {
            breakpoint: 640,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            },
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
            },
        },
    ],
};

function LojasParceiras() {
    const lojas = [
        {
            imagem: "/assets/logoLojas/logoattra.png",
            cidade: "Uberlândia - MG",
            nome: "Attra Veículos"
        },
        {
            imagem: "/assets/logoLojas/logozemaria.png",
            cidade: "Uberlândia - MG",
            nome: "Zé Maria Automóveis"
        },
        {
            imagem: "/assets/logoLojas/logoedcar.png",
            cidade: "São Bernando do Campo - SP",
            nome: "Edcar Veículos"
        }
    ];

    return (
        <div className="p-4 w-full">
            <Slider {...settings}>
                {lojas.map((loja, index) => (
                    <div
                        key={index}
                        className="flex flex-col justify-center items-center gap-4 bg-gray-100 p-2 rounded-lg shadow-md w-full sm:w-60 md:w-72 lg:w-80"
                    >
                        <div className="flex flex-col justify-center items-center">
                            <img
                                src={loja.imagem}
                                alt={loja.nome}
                                className="mb-4 object-cover rounded-md h-24"
                            />
                        </div>
                        <div className="flex flex-col items-center justify-center text-center font-main bg-red-600 text-white px-4 py-2 rounded-md">
                            <p className="text-sm">{loja.cidade}</p>
                            <h4 className="text-lg font-semibold">{loja.nome}</h4>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
}

export default function AboutPage() {
    return (
        <div>
            <Header />
            <main>
                <h1 className="flex justify-center font-main text-4xl font-bold mt-36">Sobre nós</h1>
                <section className="flex flex-col md:flex-row p-8 gap-12 mt-20">
                    <div className="font-main text-4xl font-bold">
                        <h2>Onde cada veículo se torna extraordinário</h2>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-12 font-main">
                        <div>
                            <h3 className="font-semibold text-xl">Marcas variadas</h3>
                            <p>Trabalhamos com uma ampla seleção de marcas para atender a todos os gostos e necessidades.</p>
                        </div>
                        <div>
                            <h3 className="font-semibold text-xl">Ótimas parcelas</h3>
                            <p>Oferecemos condições de pagamento flexíveis e parcelas que cabem no seu bolso.</p>
                        </div>
                        <div>
                            <h3 className="font-semibold text-xl">Suporte ao cliente</h3>
                            <p>Participe de leilões exclusivos e encontre ofertas imperdíveis.</p>
                        </div>
                        <div>
                            <h3 className="font-semibold text-xl">Leilões</h3>
                            <p>Equipe dedicada para ajudar você em todas as etapas da compra.</p>
                        </div>
                    </div>
                </section>

                <section className="mt-20">
                    <div className="font-main p-8">
                        <h2 className="text-4xl font-bold">Venha aprovar o seu financiamento conosco</h2>
                        <p className="text-neutral-500 pt-8 pb-8">Aproveite nossas condições especiais e realize o sonho de ter seu veículo. <br /> Aqui, facilitamos a aprovação do seu financiamento de forma rápida e descomplicada.</p>
                        <div className="flex flex-col md:flex-row gap-4">
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-y-8 gap-x-8">
                                <div className="flex items-center">
                                    <img className="mr-2" src="/assets/iconsVehicle/icon-check.png" alt="Icone check" />
                                    <p className="text-neutral-500">Oferecemos financiamento personalizado para cada perfil de cliente, <br /> garantindo a melhor opção para o seu orçamento.</p>
                                </div>
                                <div className="flex items-center">
                                    <img className="mr-2" src="/assets/iconsVehicle/icon-check.png" alt="Icone check" />
                                    <p className="text-neutral-500">Nossa equipe especializada está pronta para orientá-lo <br /> durante todo o processo, tornando tudo mais simples e transparente.</p>
                                </div>
                                <div className="flex items-center">
                                    <img className="mr-2" src="/assets/iconsVehicle/icon-check.png" alt="Icone check" />
                                    <p className="text-neutral-500">Trabalhamos com taxas competitivas e prazos flexíveis, <br /> para que você tenha parcelas que caibam no seu bolso.</p>
                                </div>
                                <div className="flex items-center">
                                    <img className="mr-2" src="/assets/iconsVehicle/icon-check.png" alt="Icone check" />
                                    <p className="text-neutral-500">Com a nossa parceria com as principais instituições financeiras, <br /> você tem segurança e agilidade na aprovação do crédito.</p>
                                </div>
                            </div>
                            <div className="flex justify-center items-center">
                                <img src="/assets/person1.png" alt="person1" className="max-w-xs" />
                            </div>
                        </div>
                        <Link className="flex justify-start w-fit bg-red-600 hover:bg-red-700 transform transition-transform duration-300 hover:scale-105 rounded-md shadow-md text-white text-center p-4 mt-8 font-main font-semibold" to={'/financiamento'}>
                            Financiamento
                        </Link>
                    </div>
                </section>

                <section className="mt-20">
                    <div className="font-main p-8">
                        <h2 className="text-4xl font-bold">Fique de olho nos leilões de veículos</h2>
                        <p className="text-neutral-500 pt-8 pb-8">
                            Acompanhe os leilões de veículos e encontre oportunidades únicas para adquirir carros <br /> com preços competitivos e condições exclusivas.
                        </p>
                        <div className="flex flex-col md:flex-row gap-4">
                            <div className="flex justify-center">
                                <img src="/assets/img-hammer.png" alt="leiloes" className="max-w-xs" />
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-y-8 gap-x-8">
                                <div className="flex items-center">
                                    <img className="mr-2" src="/assets/iconsVehicle/icon-check.png" alt="Icone check" />
                                    <p className="text-neutral-500">Encontre veículos de diferentes marcas e modelos em leilões <br /> organizados para atender todas as suas necessidades.</p>
                                </div>
                                <div className="flex items-center">
                                    <img className="mr-2" src="/assets/iconsVehicle/icon-check.png" alt="Icone check" />
                                    <p className="text-neutral-500">Aproveite a chance de adquirir veículos com valores <br /> atrativos e condições exclusivas, com qualidade e segurança.</p>
                                </div>
                                <div className="flex items-center">
                                    <img className="mr-2" src="/assets/iconsVehicle/icon-check.png" alt="Icone check" />
                                    <p className="text-neutral-500">Nossos leilões oferecem um ambiente seguro, com total <br /> transparência em cada etapa, para garantir uma experiência de compra tranquila.</p>
                                </div>
                                <div className="flex items-center">
                                    <img className="mr-2" src="/assets/iconsVehicle/icon-check.png" alt="Icone check" />
                                    <p className="text-neutral-500">Conte com nosso time de especialistas para orientá-lo antes, <br /> durante e após o leilão, facilitando cada decisão.</p>
                                </div>
                            </div>
                        </div>
                        <Link className="flex justify-start w-fit bg-red-600 hover:bg-red-700 transform transition-transform duration-300 hover:scale-105 rounded-md shadow-md text-white text-center px-12 py-4 mt-8 font-main font-semibold" to={'/leiloes'}>
                            Leilões
                        </Link>
                    </div>
                </section>

                <section>
                    <div className="bg-stone-800 rounded-md flex flex-col lg:flex-row justify-center items-center p-10 m-8 mt-16 gap-8">
                        <div className="flex flex-col items-start font-main text-white">
                            <h2 className="text-xl md:text-3xl mb-2 font-bold">Procurando por um carro?</h2>
                            <p className="py-6 text-lg">Encontre o carro dos seus sonhos dentro da nossa grande variedade de veículos.</p>
                            <Link className="hover:bg-red-700 transition duration-300 bg-red-600 w-fit px-10 py-2 rounded-md" to={'/comprar'}>
                                Encontrar veículo
                            </Link>
                        </div>
                        <img src="/assets/car-about.png" className="max-w-xs" />
                    </div>
                </section>

                <Footer />
            </main>
        </div>
    )
}