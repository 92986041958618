import Footer from "../../components/Footer";
import Header from "../../components/Header";

export default function Leiloes() {
    return (
        <main>
            <Header />
            <h1 className="text-center font-main text-4xl font-bold mt-28">Leilões</h1>
            <div className="flex justify-center items-center mt-12">
                <img src="/assets/img-hammerbnr.png" alt="banner leilão" />
            </div>
            <section className="font-main p-6">
                <h2 className="font-bold text-3xl">Sobre nossos leilões</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-12 mt-14 font-main">
                    <div>
                        <h3 className="font-semibold text-xl">Marcas e Modelos</h3>
                        <p>Encontre veículos de diferentes marcas e modelos em nossos leilões, desde populares até veículos de luxo.</p>
                    </div>
                    <div>
                        <h3 className="font-semibold text-xl">Oportunidades Exclusivas</h3>
                        <p>Aproveite oportunidades exclusivas para adquirir veículos com preços abaixo do mercado e condições especiais.</p>
                    </div>
                    <div>
                        <h3 className="font-semibold text-xl">Transparência e Segurança</h3>
                        <p>Participar dos nossos leilões é seguro e transparente. Todos os veículos passam por uma vistoria detalhada.</p>
                    </div>
                    <div>
                        <h3 className="font-semibold text-xl">Participação Facilitada</h3>
                        <p>Participe dos leilões online de forma fácil e rápida, com suporte especializado e acompanhamento em tempo real dos seus lances.</p>
                    </div>
                </div>
                <div className="bg-red-600 hover:bg-red-700 rounded-md py-4 px-12 mt-10 transition duration-300 w-fit text-white cursor-pointer">
                    <a href="#">Entrar em leilões</a>
                </div>
            </section>
            <Footer />
        </main>
    )
}