import { useState } from "react";
import { Link } from "react-router-dom";
import { IoMdMenu } from "react-icons/io";

export default function Header() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <header className="fixed top-0 left-0 w-full z-50 shadow-md h-16">
            <nav className="flex flex-col sm:flex-row justify-between items-center gap-4 p-4 font-main font-medium bg-stone-50">
                <button onClick={toggleMenu} className="sm:hidden ml-auto">
                    <IoMdMenu className="justify-end" size={32} />
                </button>
                <div className="flex flex-col sm:flex-row sm:items-center sm:gap-14 w-full pr-8">
                    <div className="flex justify-center items-center md:justify-start sm:mr-auto">
                        <Link to="/">
                            <img src="/assets/logo.png" alt="Logo" />
                        </Link>
                    </div>
                    <Link to="/comprar" className={`${isMenuOpen ? "block" : "hidden"} sm:block transition-transform hover:scale-110 hover:font-semibold`}>Estoque</Link>
                    <Link to="/sobre" className={`${isMenuOpen ? "block" : "hidden"} sm:block transition-transform hover:scale-110 hover:font-semibold`}>Sobre nós</Link>
                    <Link to="/financiamento" className={`${isMenuOpen ? "block" : "hidden"} sm:block transition-transform hover:scale-110 hover:font-semibold`}>Financiamento</Link>
                    <Link to="/leiloes" className={`${isMenuOpen ? "block" : "hidden"} sm:block transition-transform hover:scale-110 hover:font-semibold`}>Leilões</Link>
                    <Link to="/contatos" className={`${isMenuOpen ? "block" : "hidden"} sm:block transition-transform hover:scale-110 hover:font-semibold`}>Contatos</Link>
                </div>
            </nav>
        </header>
    );
}
