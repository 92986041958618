import { Component } from "react";
import Header from '../../components/Header';
import Footer from '../../components/Footer';


class Contatos extends Component {
    constructor(props) {
        super(props)

        this.state = {
            nome: '',
            email: '',
            telefone: '',
            mensagem: '',
            enviado: false,
            msg_enviado: '',
            url_veiculo: window.location.href,
            revendaA: {},
            inputValue: '',
            errorMessage: '',
        }

        this.mounted = false;

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(event) {
        event.preventDefault();

        this.setState({
            enviado: true,
            msg_enviado: 'Enviando mensagem...'
        });

        const mensagem = `
            Nome: ${this.state.nome} <br>
            Telefone: ${this.state.telefone} <br>
            Mensagem: ${this.state.mensagem}
        `;

        fetch('https://us-central1-intermedio-1513801146022.cloudfunctions.net/enviarEmail', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                remetente: `Contato ${this.state.revendaA.penome_fantasia} <${this.state.revendaA.peemail}>`,
                assunto: "Dúvida sobre o site - Site Ofertas Auto",
                destinatarios: "contato@ofertasautomoveis.com.br",
                corpo: mensagem,
                corpoHtml: mensagem
            })
        })
            .then(data => {
                this.setState({
                    msg_enviado: 'E-Mail Enviado com Sucesso',
                    nome: "",
                    email: "",
                    telefone: "",
                    mensagem: "",
                });
            })
            .catch(error => {
                this.setState({
                    msg_enviado: 'Erro ao enviar e-mail, tentar novamente mais tarde'
                });
            });
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        if (name === 'nome') {
            const regex = /^[a-zA-Z\s]*$/;
            if (regex.test(value)) {
                this.setState({ [name]: value, errorMessage: '' });
            } else {
                this.setState({ errorMessage: 'Por favor, insira apenas letras.' });
                return;
            }
        } else {
            this.setState({ [name]: value });
        }
    }

    render() {
        return (
            <main>
                <section>
                    <div className="bg-gradient-to-b from-gray-50 to-gray-100 min-h-screen">
                        <Header />
                        <h1 className='font-main text-4xl font-bold text-center text-gray-800 mt-36 md:mt-28'>Entre em Contato Conosco</h1>
                        <div className='flex flex-col lg:flex-row justify-center items-center gap-8 mt-16'>
                            <div className='bg-rose-600 rounded-md shadow-lg p-8 w-full max-w-md'>
                                <h2 className='text-2xl font-semibold text-center text-white font-main mb-6'>Preencha o formulário</h2>
                                <form className="flex flex-col space-y-6" onSubmit={this.handleSubmit}>
                                    <input
                                        placeholder="NOME *"
                                        onChange={this.handleChange}
                                        name="nome"
                                        type="text"
                                        value={this.state.nome}
                                        required
                                        className="bg-gray-50 border border-gray-300 rounded-md w-full max-w-xs p-4 text-lg focus:ring-2 focus:ring-amber-500 focus:outline-none transition duration-300"
                                    />

                                    <input
                                        placeholder="TELEFONE *"
                                        name="telefone"
                                        type="text"
                                        value={this.state.telefone}
                                        onChange={(event) => this.handleChange({ target: { name: 'telefone', value: event.target.value } })}
                                        required
                                        pattern="\(\d{2}\) \d{5}-\d{4}"
                                        className="bg-gray-50 border border-gray-300 rounded-md w-full max-w-xs p-4 text-lg focus:ring-2 focus:ring-amber-500 focus:outline-none transition duration-300"
                                    />

                                    <textarea
                                        name="mensagem"
                                        placeholder="Sua Mensagem *"
                                        onChange={this.handleChange}
                                        required
                                        className="bg-gray-50 border border-gray-300 rounded-md p-4 h-32 text-lg focus:ring-2 focus:ring-amber-500 focus:outline-none transition duration-300 resize-none"
                                    ></textarea>

                                    <button
                                        type="submit"
                                        className="font-main bg-amber-500 text-white py-3 rounded-md hover:bg-amber-600 transition-all duration-300 transform hover:-translate-y-1"
                                    >
                                        ENVIAR MENSAGEM <span className="fa fa-angle-right"></span>
                                    </button>

                                    {this.state.enviado && (
                                        <blockquote className="mt-4 text-center text-lg text-white">
                                            <p><em>{this.state.msg_enviado}</em></p>
                                        </blockquote>
                                    )}
                                </form>
                            </div>

                            <div className="hidden lg:block">
                                <img src='/assets/car-contacts.png' alt='car1' className="shadow-lg rounded-lg max-w-full" />
                            </div>
                        </div>
                    </div>
                </section>

                <section className='mt-10 mb-10'>
                    <div className='flex flex-col lg:flex-row justify-center items-start gap-10 font-main p-4'>
                        <div className='flex items-center gap-4'>
                            <div className='bg-amber-400 rounded-2xl w-fit p-2'>
                                <img src='/assets/mail-icon.png' alt='email' className="w-8 h-8" />
                            </div>
                            <div>
                                <p className='font-semibold text-lg'>Email</p>
                                <p className='text-gray-700'>contato@ofertasautomoveis.com.br</p>
                            </div>
                        </div>
                        <div className='flex items-center gap-4'>
                            <div className='bg-amber-400 rounded-2xl w-fit p-2'>
                                <img src='/assets/clock-icon.png' alt='horario' className="w-8 h-8" />
                            </div>
                            <div>
                                <p className='font-semibold text-lg'>Horário de Funcionamento</p>
                                <p className='text-gray-700'>Seg-Dom: 08:00 - 18:00</p>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </main>
        )
    }
}

export default Contatos;
