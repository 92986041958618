import React, { Component, lazy } from 'react';
import { firestore } from "../../services/firebase-config";
import Slider from "react-slick";
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Link, useNavigate, useParams } from 'react-router-dom';
import '../../../src/index.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import loaderGif from '../../assets/loader.gif'
import { ids_firebase } from '../../content-site';
import InputMask from 'react-input-mask';

const ImagensVeiculo = lazy(() => import('../../components/ImagensVeiculo'));
const ObservacoesVeiculo = lazy(() => import('../../components/ObservacoesVeiculo'));
const InformacoesLoja = lazy(() => import('../../components/InformacoesLoja'));
const OpcionaisVeiculo = lazy(() => import('../../components/OpcionaisVeiculo'));
const VeiculosRecentes = lazy(() => import('../../components/VeiculosRecentes'));


function VeiculosWrapper(props) {
    const { idVeiculo } = useParams();
    const navigate = useNavigate();
    return <Veiculos {...props} idVeiculo={idVeiculo} navigate={navigate} />;
}

class Veiculos extends Component {

    constructor(props) {
        super(props);

        this.state = {
            veiculo: {},
            idVeiculo: props.idVeiculo,
            nome: '',
            email: '',
            telefone: '',
            mensagem: '',
            enviado: false,
            msg_enviado: '',
            revendaA: {},
            revendas: [],
            url_veiculo: window.location.href,
            veiculosRecentes: [],
            idVeiculoSelecionado: null,
            isPopupOpen: false,
            valorEntrada: '',
            parcelas: {},
            selectedParcelas: null,
            nomeContato: '',
            telefoneContato: '',
            enviadoContato: false,
            msgEnviadoContato: '',
            isContatoPopupOpen: false,
            errorMessage: '',
            loading: true,
        };
        this.mounted = false;
        this.taxaJurosAnual = 5

        this.parcelasSectionRef = React.createRef();
        this.scrollToParcelasSection = this.scrollToParcelasSection.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeContato = this.handleChangeContato.bind(this);
        this.handleSubmitContato = this.handleSubmitContato.bind(this);
        this.toggleContatoPopup = this.toggleContatoPopup.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.fetchVeiculo = this.fetchVeiculo.bind(this);
        this.fetchVeiculosRecentes = this.fetchVeiculosRecentes.bind(this);
        this.togglePopup = this.togglePopup.bind(this);
        this.handleVehicleClick = this.handleVehicleClick.bind(this);
        this.calcularParcelas = this.calcularParcelas.bind(this);
    }

    componentDidMount() {
        const revendaPromises = ids_firebase.map(obj =>
            firestore.collection("revenda").doc(obj.id).get()
        );
    
        Promise.all(revendaPromises)
            .then(docs => {
                const revendas = docs.map(doc => doc.exists ? { id: doc.id, ...doc.data() } : null).filter(revenda => revenda !== null);
                this.setState({ revendas }, () => {
                    this.fetchVeiculo(this.state.idVeiculo);
                    this.fetchVeiculosRecentes();
                });
            })
            .catch(error => {
                console.error("Erro ao buscar revendas:", error);
            });
    
        this.fetchVeiculo(this.state.idVeiculo);
    }

    componentDidUpdate(prevProps) {
        const { idVeiculo } = this.props;

        if (idVeiculo !== prevProps.idVeiculo) {
            this.fetchVeiculo(idVeiculo);
        }
    }

    handleHidden() {
        const hiddenOut = document.getElementById("box-whatsapp");
        if (hiddenOut) {
            hiddenOut.classList.remove('hidden');
        } else {
            console.warn("Elemento com id 'box-whatsapp' não encontrado no DOM");
        }
    }

    fetchVeiculo(idVeiculo) {
        if (!idVeiculo) {
            console.error("ID do veículo é indefinido");
            return;
        }
    
        const allRevendasPromises = ids_firebase.map(obj =>
            firestore.collection("revenda").doc(obj.id).collection("veiculos").doc(idVeiculo).get()
        );
    
        Promise.all(allRevendasPromises)
            .then(revendas => {
                const veiculoDoc = revendas.find(doc => doc.exists);
                if (veiculoDoc) {
                    const veiculo = veiculoDoc.data();
                    const idRevenda = veiculoDoc.ref.parent.parent.id;
                    this.setState({ veiculo, loading: false }, () => {
                        this.fetchRevenda(idRevenda);
                    });
                } else {
                    console.error("Veículo não encontrado.");
                    this.setState({ loading: false });
                }
            })
            .catch(error => {
                console.error("Erro ao buscar informações do veículo:", error);
                this.setState({ loading: false });
            });
    }

    fetchRevenda(idRevenda) {
        firestore.collection("revenda").doc(idRevenda).get()
            .then(doc => {
                if (doc.exists) {
                    this.setState({ revendaA: doc.data() });
                } else {
                    console.error("Revenda não encontrada.");
                }
            })
            .catch(error => {
                console.error("Erro ao buscar informações da revenda:", error);
            });
    }

    fetchVeiculosRecentes = () => {
        this.setState({ loading: true });
        const allVehicles = [];
        let completedVehicleQueries = 0;
    
        ids_firebase.forEach((obj) => {
            const revendaDocRef = firestore.collection("revenda").doc(obj.id);
            revendaDocRef.collection("veiculos")
                .where("veremovido", "==", 0)
                .where("vevendido", "==", 0)
                .limit(1)
                .get()
                .then(querySnapshot => {
                    querySnapshot.forEach(doc => {
                        allVehicles.push(doc.data());
                    });
                    completedVehicleQueries++;
                    if (completedVehicleQueries === ids_firebase.length) {
                        this.setState({ veiculosRecentes: allVehicles, loading: false });
                    }
                })
                .catch(error => {
                    console.error("Erro ao buscar veículos recentes:", error);
                    this.setState({ loading: false });
                });
        });
    };

    togglePopup() {
        this.setState((prevState) => ({
            isPopupOpen: !prevState.isPopupOpen,
            enviado: false,
            nome: '',
            email: '',
            telefone: '',
            mensagem: '',
        }));
    }

    toggleContatoPopup() {
        this.setState((prevState) => ({
            isContatoPopupOpen: !prevState.isContatoPopupOpen,
            enviadoContato: false,
            nomeContato: '',
            telefoneContato: '',
        }));
    }

    renderLoading() {
        return (
            <div className="fixed inset-0 flex flex-col items-center justify-center z-50">
                <img className="w-64 h-auto" src='/assets/loading.gif' alt="Loading..." />
                <p className="text-red-500 font-semibold text-center text-xl">
                    Estamos preparando seu veículo...
                </p>
            </div>
        );
    }

    renderPopup() {
        const { errorMessage, enviado, msg_enviado } = this.state;

        return (
            <div
                id='popup'
                className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 p-4 sm:p-6 md:p-10 lg:p-20"
            >
                <div className="bg-white p-4 sm:p-6 md:p-8 rounded-lg shadow-md w-full max-w-md">
                    <div className="flex flex-col items-center justify-center space-y-4">
                        <img className="rounded-lg w-10 h-10" src='../../assets/icone-whatsapp.png' alt="WhatsApp Icon" />
                        <p className="font-main text-center" data-to='#form1'>DEIXE SEUS DADOS PARA ENTRAR EM CONTATO COM O VENDEDOR</p>
                    </div>
                    <form onSubmit={(e) => {
                        this.handleSubmit(e);
                        if (this.state.valorEntrada) {
                            this.handleHidden();
                        }
                    }} className="mt-4">
                        <div className="mb-4">
                            <label className="block mb-2" htmlFor="nome">Nome:</label>
                            <input
                                type="text"
                                id="nome"
                                name="nome"
                                value={this.state.nome}
                                onChange={this.handleChange}
                                className="p-2 border border-gray-300 rounded-lg w-full"
                                required
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block mb-2" htmlFor="telefone">Número de Telefone:</label>
                            <InputMask
                                mask="(99) 99999-9999"
                                value={this.state.telefone}
                                onChange={this.handleChange}
                            >
                                {(inputProps) => <input
                                    {...inputProps}
                                    type="tel"
                                    id="telefone"
                                    name="telefone"
                                    className="p-2 border border-gray-300 rounded-lg w-full"
                                    placeholder="(XX) XXXXX-XXXX"
                                    required
                                />}
                            </InputMask>
                        </div>
                        {
                            this.state.revendaA.petelefone2 !== undefined && this.state.revendaA.petelefone2.trim().length > 0 ? (
                                <a
                                    href={'https://api.whatsapp.com/send?text=Olá, vi um anúncio no Oferta Autos e gostaria de saber mais sobre as parcelas do veículo &phone=55' + retiraMascara(this.state.revendaA.petelefone2, "link")}
                                    target='_blank'
                                    className="text-white font-semibold hover:text-green-200"
                                >
                                    <div id='box-whatsapp' className="flex items-center bg-green-500 text-white p-4 rounded-lg shadow-md space-x-2 mt-5 transform transition duration-200 hover:scale-105 hover:shadow-lg hidden">
                                        <div>
                                            <span className="fab fa-whatsapp text-lg mr-2"></span>
                                            {retiraMascara(this.state.revendaA.petelefone2)}
                                            <p className="text-sm mt-1">Entre em contato pelo WhatsApp ou ligação</p>
                                        </div>
                                    </div>
                                </a>
                            ) : (
                                <div></div>
                            )
                        }
                        <div className="mt-5 flex flex-col sm:flex-row justify-end gap-2">
                            <button
                                type="button"
                                className="px-4 py-2 bg-gray-300 rounded-lg hover:bg-gray-400 transition duration-300"
                                onClick={this.togglePopup}
                            >
                                Cancelar
                            </button>
                            <button
                                type="submit"
                                className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition duration-300"
                            >
                                Enviar
                            </button>
                        </div>
                    </form>
                    {errorMessage && (
                        <p className="mt-2 text-red-600">{errorMessage}</p>
                    )}
                    {enviado && (
                        <p className="mt-4 text-center text-green-600">{msg_enviado}</p>
                    )}
                </div>
            </div>
        );
    }

    renderContatoPopup() {
        const { errorMessage, nomeContato, telefoneContato, enviadoContato, msgEnviadoContato } = this.state;

        return (
            <div
                id='contatoPopup'
                className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 p-4 sm:p-6 md:p-10 lg:p-20"
            >
                <div className="bg-white p-4 sm:p-6 md:p-8 rounded-lg shadow-md w-full max-w-md">
                    <div className="flex flex-col items-center justify-center space-y-4">
                        <img className="rounded-lg w-10 h-10" src='../../assets/icone-whatsapp.png' alt="WhatsApp Icon" />
                        <p className="font-main text-center" data-to='#form1'>DEIXE SEUS DADOS PARA ENTRAR EM CONTATO COM O VENDEDOR</p>
                    </div>
                    <form onSubmit={(e) => {
                        this.handleSubmitContato(e);
                        this.handleHidden();
                    }} className="mt-4">
                        <div className="mb-4">
                            <label className="block mb-2" htmlFor="nomeContato">Nome:</label>
                            <input
                                type="text"
                                id="nomeContato"
                                name="nomeContato"
                                value={nomeContato}
                                onChange={this.handleChangeContato}
                                className="p-2 border border-gray-300 rounded-lg w-full"
                                required
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block mb-2" htmlFor="telefoneContato">Número de Telefone:</label>
                            <InputMask
                                mask="(99) 99999-9999"
                                value={this.state.telefoneContato}
                                onChange={this.handleChangeContato}
                            >
                                {(inputProps) => <input
                                    {...inputProps}
                                    type="tel"
                                    id="telefoneContato"
                                    name="telefoneContato"
                                    className="p-2 border border-gray-300 rounded-lg w-full"
                                    placeholder="(XX) XXXXX-XXXX"
                                    required
                                />}
                            </InputMask>
                        </div>
                        {
                            this.state.revendaA.petelefone2 !== undefined && this.state.revendaA.petelefone2.trim().length > 0 ? (
                                <a
                                    href={'https://api.whatsapp.com/send?text=Olá, vi um anúncio no Oferta Autos e gostaria de saber mais sobre o veículo &phone=55' + retiraMascara(this.state.revendaA.petelefone2, "link")}
                                    target='_blank'
                                    className="text-white font-semibold hover:text-green-200"
                                >
                                    <div id='box-whatsapp' className="flex items-center bg-green-500 text-white p-4 rounded-lg shadow-md space-x-2 mt-5 transform transition duration-200 hover:scale-105 hover:shadow-lg hidden">
                                        <div>
                                            <span className="fab fa-whatsapp text-lg mr-2"></span>
                                            {retiraMascara(this.state.revendaA.petelefone2)}
                                            <p className="text-sm mt-1">Entre em contato pelo WhatsApp ou ligação</p>
                                        </div>
                                    </div>
                                </a>
                            ) : (
                                <div></div>
                            )
                        }
                        <div className="flex flex-col sm:flex-row justify-end gap-2 mt-5">
                            <button
                                type="button"
                                className="px-4 py-2 bg-gray-300 rounded-lg hover:bg-gray-400 transition duration-300"
                                onClick={this.toggleContatoPopup}
                            >
                                Cancelar
                            </button>
                            <button
                                type="submit"
                                className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition duration-300"
                            >
                                Enviar
                            </button>
                        </div>
                    </form>
                    {errorMessage && (
                        <p className="mt-2 text-red-600">{errorMessage}</p>
                    )}
                    {enviadoContato && (
                        <p className="mt-4 text-center text-green-600">{msgEnviadoContato}</p>
                    )}
                </div>
            </div>
        );
    }

    isFormValid() {
        return (
            this.state.nome.trim() !== '' &&
            this.state.email.trim() !== '' &&
            this.state.telefone.trim() !== '' &&
            this.state.mensagem.trim() !== ''
        );
    }

    handleVehicleClick(idVeiculo) {
        if (idVeiculo) {
            this.setState({ idVeiculoSelecionado: idVeiculo }, () => {
                this.props.navigate(`/veiculos/${idVeiculo}`);
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            });
        } else {
            console.error("ID do veículo não encontrado.");
        }
    }

    handleChange(event) {

        const target = event.target;
        let value = target.value;
        const name = target.name;

        if (name === 'valorEntrada') {
            value = this.formatCurrency(value);
        }

        this.setState({
            [name]: value
        }, this.verificaFormValido);
    }

    formatCurrency(value) {
        value = value.replace(/\D/g, "");
        const options = { minimumFractionDigits: 2, maximumFractionDigits: 2 };
        const formattedValue = new Intl.NumberFormat('pt-BR', options).format(value / 100);

        return formattedValue;
    }

    handleChangeContato(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        }, this.verificaFormValido);
    }

    verificaFormValido = () => {
        const { nome, telefone } = this.state
        const formValido = nome.trim() !== '' && telefone.trim() !== '';
        this.setState({ formValido });
    }

    calcularParcelas() {
        const { valorEntrada, veiculo } = this.state;

        if (!valorEntrada) {
            this.setState({ errorMessage: 'Por favor, preencha o valor de entrada.' });
            return;
        }

        const PV_total = parseFloat(veiculo.vevalor);
        const entrada = parseFloat(valorEntrada.replace(/\D/g, "")) / 100;
        const i = this.taxaJurosAnual / 100 / 12;

        if (entrada >= PV_total) {
            this.setState({ errorMessage: 'O valor de entrada deve ser menor que o valor total do veículo.' });
            return;
        }

        const PV_financiado = PV_total - entrada;
        const prazos = [12, 24, 36, 48, 60];
        const parcelas = {};

        prazos.forEach((n) => {
            const valorParcela = (PV_financiado * i) / (1 - Math.pow(1 + i, -n));
            parcelas[n] = valorParcela.toFixed(2);
        });

        this.setState({ parcelas, errorMessage: '' });
    }

    scrollToParcelasSection() {
        this.parcelasSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }

    handleParcelasClick = (prazo, valor) => {
        this.setState({ selectedParcelas: { prazo, valor } })
    }

    handleSubmit(event) {
        event.preventDefault();

        const { valorEntrada } = this.state;

        if (!valorEntrada) {
            this.setState({ errorMessage: 'Por favor, preencha o valor de entrada.' });
            return;
        }

        this.setState({
            enviado: true,
            msg_enviado: 'Enviando mensagem...'
        });

        const mensagem = `
            Nome: ${this.state.nome} <br>
            E-mail: ${this.state.email} <br>
            Telefone: ${this.state.telefone} <br>
            Veículo: ${this.state.url_veiculo} <br>
            Mensagem: ${this.state.mensagem} <br>
            Valor da Entrada: R$ ${this.state.valorEntrada} <br>
            Parcelas: ${this.state.selectedParcelas.prazo} R$ ${this.state.selectedParcelas.valor}
        `;

        fetch('https://us-central1-intermedio-1513801146022.cloudfunctions.net/enviarEmail', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                remetente: `Contato ${this.state.revendaA.penome_fantasia} <${this.state.revendaA.peemail}>`,
                assunto: `Interesse no veículo - Site Oferta Autos - ${this.state.revendaA.penome_fantasia}`,
                destinatarios: this.state.revendaA.peemail,
                corpo: mensagem,
                corpoHtml: mensagem
            })
        })
            .then(response => {
                if (response.ok) {
                    this.setState({
                        msg_enviado: 'E-Mail Enviado com Sucesso',
                        nome: "",
                        email: "",
                        telefone: "",
                        mensagem: "",
                    });
                } else {
                    this.setState({
                        msg_enviado: 'Erro ao enviar e-mail, tentar novamente mais tarde'
                    });
                }
            })
            .catch(error => {
                console.error('Erro ao enviar e-mail:', error);
                this.setState({
                    msg_enviado: 'Erro ao enviar e-mail, tentar novamente mais tarde'
                });
            });
    }


    handleSubmitContato(event) {
        event.preventDefault();

        this.setState({
            enviadoContato: true,
            msgEnviadoContato: 'Enviando mensagem...'
        });

        const mensagem = `
            Nome: ${this.state.nomeContato} <br>
            Telefone: ${this.state.telefoneContato} <br>
        `;

        fetch('https://us-central1-intermedio-1513801146022.cloudfunctions.net/enviarEmail', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                remetente: `Contato ${this.state.revendaA.penome_fantasia} <${this.state.revendaA.peemail}>`,
                assunto: `Contato - Site Ofertas Auto - ${this.state.revendaA.penome_fantasia}`,
                destinatarios: this.state.revendaA.peemail,
                corpo: mensagem,
                corpoHtml: mensagem
            })
        })
            .then(response => {
                if (response.ok) {
                    this.setState({
                        msgEnviadoContato: 'E-Mail Enviado com Sucesso',
                        nomeContato: "",
                        telefoneContato: "",
                    });
                } else {
                    this.setState({
                        msgEnviadoContato: 'Erro ao enviar e-mail, tentar novamente mais tarde'
                    });
                }
            })
            .catch(error => {
                console.error('Erro ao enviar e-mail:', error);
                this.setState({
                    msgEnviadoContato: 'Erro ao enviar e-mail, tentar novamente mais tarde'
                });
            });
    }

    render() {
        const { veiculo, revendaA, veiculosRecentes, valorEntrada, parcelas, selectedParcelas, loading } = this.state;

        const valorEntradaNumerico = parseFloat(valorEntrada.replace(/\D/g, "")) / 100;

        if (loading) {
            return this.renderLoading();
        }

        return (
            <div>
                <Header />

                <section className='mt-10'>
                    <div className="flex flex-col lg:flex-row justify-center items-center">
                        <div className="w-full lg:w-1/2 p-2 md:mb-60">
                            <h1 className="ml-6 mt-20 md:mt-20 pb-4 text-xl md:text-3xl font-main font-bold">
                                {veiculo ? `${veiculo.vefipe_marca} ${veiculo.vefipe_name}` : "Carregando..."}
                            </h1>
                            <ImagensVeiculo imagens={veiculo.imagens} />
                        </div>

                        <section className="w-full lg:w-1/2 flex flex-col items-center md:mt-8">

                            <div className='flex flex-col justify-center items-center'>
                                <div className='mt-8'>
                                    <h3 className='font-main ml-6 font-bold text-xl'>Especificações Técnicas</h3>
                                    <ul className='grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-3 gap-12 p-6 font-main'>
                                        {[
                                            { icon: '../assets/iconsVehicle/icon-distance.png', label: 'Km', value: veiculo.vequilometragem },
                                            { icon: '../assets/iconsVehicle/icon-gear.png', label: 'Ano', value: `${veiculo.veano_fabricacao} / ${veiculo.vefipe_ano_modelo}` },
                                            { icon: '../assets/iconsVehicle/icon-people.png', label: 'IPVA Pago', value: formatIpva(veiculo.veipva_pago) },
                                            { icon: '../assets/iconsVehicle/icon-gear.png', label: 'Câmbio', value: formatCambio(veiculo.vecambio) },
                                            { icon: '../assets/iconsVehicle/icon-gasoline.png', label: 'Combustível', value: veiculo.vefipe_combustivel },
                                            { icon: '../assets/iconsVehicle/icon-door.png', label: 'Portas', value: veiculo.veportas },
                                        ].map(({ icon, label, value }, index) => (
                                            <li key={index} className='bg-gray-100 rounded-md shadow-md p-4'>
                                                <img src={icon} alt={`${label} Icon`} />
                                                <p className='font-bold mt-4'>{label}</p>
                                                <p>{value}</p>
                                            </li>
                                        ))}
                                    </ul>
                                </div>

                                <div className='flex flex-col items-center md:flex-row gap-4 mt-6'>
                                    <h2 className='text-red-600 text-4xl md:text-5xl font-bold mb-4 text-left'>{formatMoney(veiculo.vevalor)}</h2>

                                    {this.state.revendaA && (
                                        <div className='flex flex-col space-y-8 p-4'>
                                            <button
                                                onClick={this.toggleContatoPopup}
                                                className='bg-red-600 hover:bg-red-700 transform transition-transform duration-300 hover:scale-105 rounded-md shadow-md text-white text-center px-4 md:px-16 py-2 w-full md:w-auto font-main font-semibold'
                                            >
                                                Contate-nos
                                            </button>

                                            <button
                                                className='bg-red-600 hover:bg-red-700 transform transition-transform duration-300 hover:scale-105 rounded-md shadow-md text-white text-center px-4 md:px-16 py-2 w-full md:w-auto font-main font-semibold'
                                                onClick={this.scrollToParcelasSection}
                                            >
                                                Calcular Parcelas
                                            </button>
                                        </div>
                                    )}

                                    {this.state.isContatoPopupOpen && this.renderContatoPopup()}
                                </div>
                            </div>

                            <section className="p-6 mb-20">
                                <div className="bg-white p-6 rounded-md shadow-md mt-4">
                                    <h2 className="text-xl font-main font-bold mb-4">DESCRIÇÃO</h2>
                                    <div className="flex flex-wrap gap-2">
                                        <div className="w-full flex gap-4 md:w-1/2">
                                            <h4 className='font-semibold'>Marca:</h4>
                                            <p>{veiculo.vefipe_marca}</p>
                                        </div>
                                        <div className="w-full flex gap-4 md:w-1/2">
                                            <h4 className='font-semibold'>Modelo:</h4>
                                            <p>{veiculo.vefipe_name}</p>
                                        </div>
                                        <div className="w-full flex gap-4 md:w-1/2">
                                            <h4 className='font-semibold'>Km:</h4>
                                            <p>{veiculo.vequilometragem} km</p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <ObservacoesVeiculo veobservacao={veiculo.veobservacao} />
                                </div>
                                <div>
                                    <InformacoesLoja
                                        pecidade={revendaA.pecidade}
                                        peendereco_fiscal={revendaA.peendereco_fiscal}
                                        pecep_fiscal={revendaA.pecep_fiscal}
                                        peendereco_fiscal_numero={revendaA.peendereco_fiscal_numero}
                                        peendereco_fiscal_compl={revendaA.peendereco_fiscal_compl}
                                        peuf={revendaA.peuf}
                                        pebairro_fiscal={revendaA.pebairro_fiscal}
                                    />
                                </div>
                            </section>

                            <div className='mt-8 w-full'>
                                <h2 className='font-main font-bold text-xl text-left'>Opcionais do Veículo</h2>
                                <div className='mt-2 w-full'>
                                    <OpcionaisVeiculo opcionais={veiculo.opcionais} />
                                </div>
                            </div>

                        </section>
                    </div>

                    <section ref={this.parcelasSectionRef} className="m-4 p-4 sm:p-6 md:p-8 bg-white rounded-xl shadow-md">
                        <h2 className="font-main font-semibold text-lg sm:text-xl">CALCULE SUA PARCELA</h2>

                        <div className="font-main mt-4">
                            <div className='flex flex-col sm:flex-row items-center gap-4 sm:gap-8 mt-2'>
                                <input
                                    type="text"
                                    name="valorEntrada"
                                    value={valorEntrada}
                                    onChange={this.handleChange}
                                    className="p-2 sm:p-4 border border-slate-300 rounded-lg w-full sm:w-fit"
                                    placeholder="R$ - Entrada"
                                />
                                <button
                                    onClick={this.calcularParcelas}
                                    className="w-full sm:w-auto px-4 sm:px-6 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition duration-300"
                                >
                                    Calcular Parcelas
                                </button>
                            </div>

                            <h3 className="font-semibold mt-4">Parcelas</h3>

                            <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-2">
                                {Object.keys(parcelas).map((prazo) => (
                                    <li
                                        key={prazo}
                                        className="flex flex-col items-center border border-slate-200 rounded-xl p-4 sm:p-6 hover:bg-red-600 hover:text-white transition duration-300 cursor-pointer"
                                        onClick={() => this.handleParcelasClick(prazo, parcelas[prazo])}
                                    >
                                        <span className="text-center">{prazo} meses</span>
                                        <span className="text-center">R$ {parcelas[prazo]}</span>
                                    </li>
                                ))}
                            </ul>

                            {selectedParcelas && (
                                <div className="font-main items-center mt-4 p-4 border rounded-lg bg-white w-full sm:w-fit">
                                    <div className="flex justify-between items-center mb-2">
                                        <p>Valor do veículo</p>
                                        <p>R$ {formatMoney(veiculo.vevalor)}</p>
                                    </div>
                                    <div className="flex justify-between items-center mb-2">
                                        <p>Entrada</p>
                                        <p>-R$ {formatMoney(valorEntradaNumerico)}</p>
                                    </div>
                                    <div className="flex justify-between items-center mb-2">
                                        <p>Valor a financiar</p>
                                        <p>R$ {formatMoney(veiculo.vevalor - parseFloat(valorEntrada.replace(/\D/g, "")) / 100)}</p>
                                    </div>
                                    <hr className="my-2 mb-4 border-rose-600" />
                                    <div className="flex justify-between items-center gap-4 sm:gap-10">
                                        <p className='font-semibold'>Parcela estimada</p>
                                        <h2 className='font-bold text-lg sm:text-xl text-red-600'>{selectedParcelas.prazo} meses R$ {selectedParcelas.valor}</h2>
                                    </div>
                                </div>
                            )}

                            <p className="mt-4 text-xs sm:text-sm">
                                *Essa simulação é baseada na média de juros do mercado, podendo ser alterada de acordo com o banco ou outras especificações, não obrigando o Ofertas Auto em mantê-la.
                            </p>

                            <button
                                onClick={this.togglePopup}
                                className="mt-6 w-full sm:w-auto px-6 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition duration-300"
                            >
                                Entrar em contato
                            </button>

                            {this.state.isPopupOpen && this.renderPopup()}
                        </div>
                    </section>

                    <div>
                        <div className='flex items-center'>
                            <h2 className='text-2xl font-main font-bold ml-4'>Outros veículos</h2>
                            <div className='flex items-center gap-4 ml-auto mr-4'>
                                <Link className='text-lg font-main font-bold' to={'/comprar'}>Ver mais</Link>
                                <img src='../assets/iconsVehicle/icon-arrow.png' alt="Ícone de seta" />
                            </div>
                        </div>

                        <div>
                            {loading ? (

                                <div className="flex justify-center items-center h-40">
                                    <img src={loaderGif} alt="Carregando..." className="w-40 h-40" />
                                </div>
                            ) : (

                                <VeiculosRecentes veiculos={veiculosRecentes} onVehicleClick={this.handleVehicleClick} />
                            )}
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        );
    }
}

export default VeiculosWrapper

function formatMoney(value) {

    var places = 2;
    var symbol = "R$ ";
    var thousand = ".";
    var decimal = ",";

    places = !isNaN(places = Math.abs(places)) ? places : 2;
    symbol = symbol !== undefined ? symbol : "$";
    thousand = thousand || ",";
    decimal = decimal || ".";
    var number = value,
        negative = number < 0 ? "-" : "",
        i = parseInt(number = Math.abs(+number || 0).toFixed(places), 10) + "",
        j = (j = i.length) > 3 ? j % 3 : 0;
    return symbol + negative + (j ? i.substr(0, j) + thousand : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousand) + (places ? decimal + Math.abs(number - i).toFixed(places).slice(2) : "");
};

function formatCambio(value) {
    if (value === 'A') {
        return "Automático";
    } else {
        return "Manual";
    }
}

function formatIpva(value) {
    if (value === 'false') {
        return "Não"
    } else {
        return "Sim"
    }
}

function retiraMascara(value, type = "texto") {
    var numero = '';
    if (value !== undefined) {
        numero = value.replace('_', '');
        if (type === "link") {
            numero = numero.replace('(', '').replace(')', '').replace('-', '').replace(' ', '');
        }
    }
    return numero;
}
