import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import Leiloes from "./pages/Leiloes";
import Financiamento from "./pages/Financiamento";
import { Component } from "react";
import Comprar from "./pages/Comprar";
import Veiculos from "./pages/Veiculo";
import AboutPage from "./pages/Sobre";
import Contatos from "./pages/Contatos";

class App extends Component {
  render() {
    return (
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/comprar" element={<Comprar />} />
          <Route path="/financiamento" element={<Financiamento />} />
          <Route path="/leiloes" element={<Leiloes />} />
          <Route path="/veiculos/:idVeiculo" element={<Veiculos />}/>
          <Route path="/contatos" element={<Contatos />} />
          <Route path="/sobre" element={<AboutPage />} />
        </Routes>
      </Router>
    );
  }
}

export default App;
