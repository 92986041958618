import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/database';
import Rebase from 're-base';

const firebaseConfig = {
    apiKey: "AIzaSyDU7Y0AEbymFQ2bC2H4CXcugCnPm4cLOeg",
    authDomain: "intermedio-1513801146022.firebaseapp.com",
    databaseURL: "https://intermedio-1513801146022.firebaseio.com",
    projectId: "intermedio-1513801146022",
    storageBucket: "intermedio-1513801146022.appspot.com",
    messagingSenderId: "534908779124"
};


const app = firebase.initializeApp(firebaseConfig);
const base = Rebase.createClass(app.database());
export const storage = firebase.storage();
export const firestore = firebase.firestore();

export default base;
