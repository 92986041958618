import Header from "../../components/Header";
import { Component } from "react";
import Footer from "../../components/Footer";
import { Link } from "react-router-dom";

class Financiamento extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dados_financiamento: '',
            cpf: '',
            nome: '',
            data_nascimento: '',
            nome_mae: '',
            cidade: '',
            cidade_uf: '',
            com_cidade: '',
            ref1_nome: '',
            ref1_telefone: '',
            enviado: false,
            msg_enviado: '',
            revenda: {},
            cpfErro: '',
            data_nascimento: '',
            dataNascimentoErro: ''
        }
        this.mounted = false;

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDataNascimentoChange = this.handleDataNascimentoChange.bind(this);
        this.handleCpfChange = this.handleCpfChange.bind(this);
        this.TestaCPF = this.TestaCPF.bind(this);

    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        if (name === 'nome' || name === 'nome_mae' || name === 'com_cidade') {
            const regex = /^[a-zA-Z\s]*$/;
            if (regex.test(value)) {
                this.setState({ [name]: value, errorMessage: '' });
            } else {
                this.setState({ errorMessage: 'Por favor, insira apenas letras.' });
                return;
            }
        } else if (name === 'cpf') {
            this.setState({ cpf: value, cpfErro: '' });
        } else {
            this.setState({ [name]: value });
        }
    }


    handleSubmit(event) {
        event.preventDefault();

        const resultadoValidacaoCPF = this.TestaCPF(this.state.cpf);

        if (!resultadoValidacaoCPF.isValid) {
            this.setState({
                cpfErro: resultadoValidacaoCPF.message,
                enviado: false,
                msg_enviado: 'CPF Inválido'
            });
            return;
        } else {
            this.setState({
                cpfErro: '',
                enviado: true,
                msg_enviado: 'Dados enviados com sucesso!',
            });
        }

        this.setState({ enviado: true, msg_enviado: 'Enviando mensagem...' });

        var mensagem = "<strong>Dados do financiamento:</strong><br><br>" + this.state.dados_financiamento;
        mensagem += "<br><br><strong>Dados pessoais</strong><br>";
        mensagem += "<br>CPF: " + this.state.cpf;
        mensagem += "<br>Nome: " + this.state.nome;
        mensagem += "<br>Data de nascimento: " + this.state.data_nascimento;
        mensagem += "<br>Nome da mãe: " + this.state.nome_mae;

        mensagem += "<br><br><strong>Dados comerciais</strong><br>";
        mensagem += "<br>Cidade: " + this.state.com_cidade + "-" + this.state.com_uf;

        mensagem += "<br><br><strong>Referências pessoais</strong><br>";
        mensagem += "<br>Nome referência 01: " + this.state.ref1_nome;
        mensagem += "<br>Telefone referência 01: " + this.state.ref1_telefone;

        fetch('https://us-central1-intermedio-1513801146022.cloudfunctions.net/enviarEmail', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                remetente: "Contato " + this.state.revenda.penome_fantasia + "<" + this.state.revenda.peemail + ">",
                assunto: "Simulação de Financiamento - Site Ofertas Auto",
                destinatarios: "contato@ofertasautomoveis.com.br",
                corpo: mensagem,
                corpoHtml: mensagem
            })
        })
            .then(data => {
                this.setState({
                    enviado: true,
                    msg_enviado: 'E-Mail enviado com sucesso, em breve você receberá um retorno da nossa equipe.',
                    dados_financiamento: '',
                    cpf: '',
                    nome: '',
                    data_nascimento: '',
                    nome_mae: '',
                    com_cidade: '',
                    com_cidade_uf: '',
                    ref1_nome: '',
                    ref1_telefone: '',
                });
                setInterval(() => this.setState({ enviado: false }), 5000);
            })
            .catch(error => {
                this.setState({ enviado: true, msg_enviado: 'Erro ao enviar e-mail, tentar novamente mais tarde' });
                setInterval(() => this.setState({ enviado: false }), 5000);
            });
    }

    handleDataNascimentoChange(e) {
        let value = e.target.value.replace(/\D/g, '');

        if (value.length > 2) {
            value = value.replace(/(\d{2})(\d)/, '$1/$2');
        }
        if (value.length > 5) {
            value = value.replace(/(\d{2})(\d{1,4})$/, '$1/$2');
        }

        this.setState({ data_nascimento: value, dataNascimentoErro: '' });
    }

    handleCpfChange(e) {
        let value = e.target.value.replace(/\D/g, '');

        if (value.length > 3) {
            value = value.replace(/(\d{3})(\d)/, '$1.$2');
        }
        if (value.length > 6) {
            value = value.replace(/(\d{3})(\d)/, '$1.$2');
        }
        if (value.length > 9) {
            value = value.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
        }

        this.setState({ cpf: value, cpfErro: '' });

        const validation = this.TestaCPF(value);
        if (!validation.isValid) {
            this.setState({ cpfErro: validation.message });
        }
    }

    TestaCPF(strCPF) {
        strCPF = strCPF.replace(/[^\d]/g, '');

        if (strCPF.length !== 11) {
            return { isValid: false, message: 'O CPF deve ter 11 dígitos.' };
        }

        if (/^(\d)\1+$/.test(strCPF)) {
            return { isValid: false, message: 'CPF inválido: todos os dígitos são iguais.' };
        }

        let Soma = 0;
        let Resto;

        for (let i = 1; i <= 9; i++) {
            Soma += parseInt(strCPF.substring(i - 1, i)) * (11 - i);
        }
        Resto = (Soma * 10) % 11;

        if (Resto === 10 || Resto === 11) Resto = 0;
        if (Resto !== parseInt(strCPF.substring(9, 10))) {
            return { isValid: false, message: 'CPF inválido: dígito verificador incorreto.' };
        }

        Soma = 0;
        for (let i = 1; i <= 10; i++) {
            Soma += parseInt(strCPF.substring(i - 1, i)) * (12 - i);
        }
        Resto = (Soma * 10) % 11;

        if (Resto === 10 || Resto === 11) Resto = 0;
        if (Resto !== parseInt(strCPF.substring(10, 11))) {
            return { isValid: false, message: 'CPF inválido: dígito verificador incorreto.' };
        }

        return { isValid: true, message: '' };
    }


    render() {
        return (
            <main>
                <Header />
                <section className="px-4 md:px-8 lg:px-16">
                    <h1 className="text-center font-main font-bold text-3xl sm:text-4xl mt-40 md:mt-20 lg:mt-28">
                        Financiamento
                    </h1>
                    <section className="flex justify-center mt-8 px-4">
                        <div className="flex flex-col md:flex-row items-center gap-6 md:gap-8 bg-stone-800 p-6 md:p-8 lg:p-10 rounded-xl w-full max-w-4xl">
                            <img src="/assets/person1.png" alt="financiamento" className="w-auto h-40 md:h-48 lg:h-56" />
                            <div className="text-white font-main space-y-6 md:space-y-10 text-center md:text-left">
                                <div className="flex flex-col">
                                    <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-2">Faça o seu financiamento</h2>
                                    <p className="text-sm sm:text-base md:text-lg">
                                        Financiar seu próximo veículo é a escolha ideal para quem deseja dirigir o carro dos sonhos sem precisar pagar tudo de uma vez.
                                        <br />
                                        Com parcelas que cabem no seu bolso, você tem mais liberdade financeira e pode escolher um automóvel novo e moderno, com toda a segurança e conforto.
                                    </p>
                                    <p className="text-sm sm:text-base md:text-lg mt-2" >*Acesse o estoque, selecione o veículo e financie seu veículo</p>
                                    <Link className="mt-4 rounded-md bg-red-600 hover:bg-red-700 transition duration-300 w-fit px-8 py-2 text-center" to={'/comprar'}>Acessar</Link>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>

                <Footer />
            </main>
        )
    }
}
export default Financiamento;

