import Slider from "react-slick";
import Header from "../../components/Header";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Component, useMemo } from "react";
import { ids_firebase } from "../../content-site";
import { firestore } from "../../services/firebase-config";
import { SelectValidator, TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import Footer from "../../components/Footer";
import { MenuItem } from "@material-ui/core";
import React, { useState, useEffect } from 'react';
import LazyLoad from "react-lazyload";

const VeiculosRecentes = React.memo(({ veiculos }) => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (veiculos.length > 0) {
            setLoading(false);
        }
    }, [veiculos]);

    const limitedVehicles = useMemo(() => veiculos.slice(0, 12), [veiculos]);

    const settingsRecents = {
        dots: false,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 4,
        autoplay: true,
        arrows: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ]
    };

    return (
        <>
            {loading ? (
                <div className="flex justify-center items-center">
                    <img src="../assets/loader.gif" alt="Carregando..." className="w-32 h-32" />
                </div>
            ) : (
                <Slider {...settingsRecents}>
                    {limitedVehicles.map((v) => (
                        <div className="p-2 sm:p-3" key={v.id}>
                            <div className="flex flex-col items-center bg-white rounded-2xl p-2 h-auto shadow-md">
                                <Link to={`/veiculos/${v.id}`}>
                                    <div className="flex justify-center">
                                        {v.imagens.length > 0 ? (
                                            v.imagens[0].fileThumb !== undefined ? (
                                                <LazyLoad height={200} offset={100}>
                                                    <img className="rounded-md object-cover h-60" src={v.imagens[0].fileThumb} alt="" />
                                                </LazyLoad>
                                            ) : (
                                                <LazyLoad height={200} offset={100}>
                                                    <img className="rounded-md object-cover h-60" src={v.imagens[0].fileUrl} alt="" />
                                                </LazyLoad>
                                            )
                                        ) : (
                                            <LazyLoad height={200} offset={100}>
                                                <img className="rounded-md object-cover h-60" src="/img/car_default.png" alt="imagem veículo" />
                                            </LazyLoad>
                                        )}
                                    </div>
                                </Link>
                                <div className="text-center font-main font-bold mt-4 text-sm sm:text-base">
                                    <Link className="block" to={`/veiculos/${v.id}`}>
                                        {v.vefipe_marca}<br /> {v.vefipe_name}
                                    </Link>
                                </div>
                                <div>
                                    <span>{v.vefipe_ano_modelo}</span>
                                </div>
                                <div className="flex flex-col sm:flex-row justify-center gap-y-2 sm:gap-x-10 items-center w-full mt-2 font-semibold">
                                    <span className="text-red-600 text-xl font-bold">{formatMoney(v.vevalor)}</span>
                                    <div className="bg-red-600 hover:bg-red-700 transform transition-transform duration-300 hover:scale-105 p-2 text-white rounded-2xl text-sm sm:text-base">
                                        <Link to={`/veiculos/${v.id}`}>Ver detalhes</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            )}
        </>
    );
});

function withNavigation(Component) {
    return function WrappedComponent(props) {
        const navigate = useNavigate();
        const location = useLocation();
        return <Component {...props} navigate={navigate} location={location} />;
    };
}


class HomePage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            veiculos: [],
            veiculosComplete: [],
            countVeiculos: 0,
            instagramData: [],
            buscaVeiculo: '',
            buscaCidade: '',
            tipoVeiculo: 'todos',
            revenda: {},
            bannerData: [],
            lastVisible: null,
            currentVehicles: [],
            hasMore: true,
            loading: false,
            isLoading: true,
            marca: '',
            modelo: '',
            vehiclesPerPage: 7,
            inputValue: '',
            inputValueModelo: '',
            suggestions: [],
            modelSuggestions: [],
            filteredModels: [],
            filteredBrands: [],
            selectedMarca: '',
        };

        this.mounted = false;

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleVehicleByCity = this.handleVehicleByCity.bind(this);
    }

    async componentDidMount() {
        this.setState({ isLoading: true });
    
        const revendaMap = {};
        const allVehicles = [];
        const initialVehicles = [];
    
        try {
            await Promise.all(Object.values(ids_firebase).map(async (obj) => {
                const revendaDoc = await firestore.collection("revenda").doc(obj.id).get();
                if (revendaDoc.exists) {
                    revendaMap[obj.href] = revendaDoc.data();
                } else {
                    revendaMap[obj.href] = { ativo: 0 };
                }
    
                const vehicleSnapshot = await firestore.collection("revenda").doc(obj.id)
                    .collection("veiculos")
                    .where("veremovido", "==", 0)
                    .where("vevendido", "==", 0)
                    .limit(3)
                    .get();
    
                vehicleSnapshot.forEach(doc => {
                    const vehicle = doc.data();
                    vehicle.id = doc.id;
                    vehicle.href_revenda = obj.href;
                    if (vehicle["veexibir_site"] !== false) {
                        initialVehicles.push(vehicle);
                    }
                });
            }));
    
            const { search } = this.props.location;
            const params = new URLSearchParams(search);
            const marca = params.get('marca') || '';
            const modelo = params.get('modelo') || '';
    
            const filteredVehicles = initialVehicles.filter(veiculo => {
                const matchesMarca = marca ? veiculo.vefipe_marca.toLowerCase().includes(marca.toLowerCase()) : true;
                const matchesModelo = modelo ? veiculo.vefipe_name.toLowerCase().includes(modelo.toLowerCase()) : true;
                return matchesMarca && matchesModelo;
            });
    
            this.setState({
                revenda: revendaMap,
                veiculos: filteredVehicles,
                veiculosComplete: filteredVehicles,
                currentVehicles: filteredVehicles,
                isLoading: false,
            });
    
            this.loadRemainingVehicles();
        } catch (error) {
            console.error("Error fetching data: ", error);
            this.setState({ isLoading: false });
        }
    }

    loadRemainingVehicles = async () => {
        const allVehicles = [...this.state.veiculosComplete];
    
        try {
            await Promise.all(Object.values(ids_firebase).map(async (obj) => {
                const vehicleSnapshot = await firestore.collection("revenda").doc(obj.id)
                    .collection("veiculos")
                    .where("veremovido", "==", 0)
                    .where("vevendido", "==", 0)
                    .get();
    
                vehicleSnapshot.forEach(doc => {
                    const vehicle = doc.data();
                    vehicle.id = doc.id;
                    vehicle.href_revenda = obj.href;
                    if (vehicle["veexibir_site"] !== false) {
                        allVehicles.push(vehicle);
                    }
                });
            }));
    
            const { search } = this.props.location;
            const params = new URLSearchParams(search);
            const marca = params.get('marca') || '';
            const modelo = params.get('modelo') || '';
    
            const filteredVehicles = allVehicles.filter(veiculo => {
                const matchesMarca = marca ? veiculo.vefipe_marca.toLowerCase().includes(marca.toLowerCase()) : true;
                const matchesModelo = modelo ? veiculo.vefipe_name.toLowerCase().includes(modelo.toLowerCase()) : true;
                return matchesMarca && matchesModelo;
            });
    
            this.setState({
                veiculos: filteredVehicles,
                veiculosComplete: filteredVehicles,
            });
    
        } catch (error) {
            console.error("Error fetching data: ", error);
            this.setState({ isLoading: false });
        }
    };


    componentWillUnmount() {
        this.mounted = false;
    }

    handlePageChange(pageNumber) {
        this.setState({ currentPage: pageNumber }, () => {
            this.fetchVeiculos();
        });
    }

    handleShowMoreVehicles = () => {
        this.setState((prevState) => ({
            vehiclesPerPage: prevState.vehiclesPerPage + 7
        }));
    }

    handleChange(event) {

        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        let filtro = this.state.buscaVeiculo.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
        let tipoVeiculo = this.state.tipoVeiculo;

        let veiculosFilter = this.state.veiculosComplete.filter(function (veiculo) {
            return veiculo.vefipe_name.toLowerCase().includes(filtro) && (tipoVeiculo === 'todos' || veiculo.tipo === tipoVeiculo);
        });

        this.setState({
            veiculos: veiculosFilter
        });
    }

    handleVehicleByCity() {
        window.scrollTo(0, 0);
        let search = this.state.buscaCidade
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, "");

        if (search) {
            let vehiclesInCity = this.state.veiculosComplete.filter(vehicle => {
                let revenda = this.state.revenda[vehicle.href_revenda];

                if (!revenda) {
                    return false;
                }

                let cidadeRevenda = revenda.pecidade
                    .toLowerCase()
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, "");

                return cidadeRevenda.includes(search);
            });

            this.setState({ currentVehicles: vehiclesInCity }, () => {
                window.scrollTo({
                    top: 1300,
                    behavior: 'smooth'
                });
            });
        } else {
            this.setState({ currentVehicles: this.state.veiculosComplete }, () => {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            });
        }
    }

    buscarPorMarcaModelo = (modelo) => {
        let veiculosFilter = this.state.veiculosComplete.filter(veiculo => {
            const veiculoMarca = this.state.inputValue ? this.state.inputValue.toLowerCase() : '';
            const veiculoModelo = this.state.inputValueModelo ? this.state.inputValueModelo.toLowerCase() : '';

            return veiculoMarca.includes(this.state.inputValue.toLowerCase()) && veiculoModelo.includes(this.state.inputValueModelo.toLowerCase());
        });

        const veiculosPorLoja = {};
        veiculosFilter.forEach(veiculo => {
            if (!veiculosPorLoja[veiculo.href_revenda]) {
                veiculosPorLoja[veiculo.href_revenda] = [];
            } if (veiculosPorLoja[veiculo.href_revenda].length < 3) {
                veiculosPorLoja[veiculo.href_revenda].push(veiculo);
            }
        });

        const firstVehicles = Object.values(veiculosPorLoja).flat();

        this.setState({
            veiculos: firstVehicles,
            currentVehicles: veiculosFilter,
        }, () => {
            this.props.navigate(`/comprar?marca=${encodeURIComponent(this.state.inputValue)}&modelo=${encodeURIComponent(this.state.inputValueModelo)}`);
        });

        this.loadRemainingVehicles(veiculosFilter);
    };

    handleClick(event) {
        this.setState({
            veiculos: this.state.veiculosComplete,
            buscaVeiculo: '',
            tipoVeiculo: 'todos',
        });
    }

    renderPageNumbers() {
        const { vehiclesPerPage } = this.state;
        const totalPages = Math.ceil(ids_firebase.length / vehiclesPerPage);
        return Array.from({ length: totalPages }, (_, index) => (
            <button key={index} onClick={() => this.handlePageChange(index + 1)}>
                {index + 1}
            </button>
        ));
    }

    checkForMistakes = (value) => {
        const carBrands = [...new Set(this.state.veiculosComplete.map(veiculo => veiculo.vefipe_marca))];
        const suggestions = carBrands.filter(brand => brand.toLowerCase().includes(value.toLowerCase()));
        return suggestions;
    }

    handleInputChange = (event) => {
        const userInput = event.target.value;
        this.setState({ inputValue: userInput, selectedMarca: userInput });

        if (userInput.trim() === "") {
            this.setState({ suggestions: [] });
        } else {
            const suggestions = this.checkForMistakes(userInput);
            this.setState({ suggestions });
        }
    }

    handleInputChangeModel = (event) => {
        const userInput = event.target.value;
        this.setState({ inputValueModelo: userInput });

        if (userInput.trim() === "") {
            this.setState({ modelSuggestions: [], filteredBrands: [] });
        } else {
            const modelSuggestions = this.state.veiculosComplete
                .filter(veiculo => veiculo.vefipe_marca.toLowerCase() === this.state.selectedMarca.toLowerCase() && veiculo.vefipe_name.toLowerCase().startsWith(userInput.toLowerCase()))
                .map(veiculo => veiculo.vefipe_name);
            this.setState({ modelSuggestions });
        }
    }

    handleSuggestionClick = (suggestion) => {
        this.setState({ inputValue: suggestion, suggestions: [], selectedMarca: suggestion });
    }

    handleSuggestionClickModel = (suggestion) => {
        this.setState({ inputValueModelo: suggestion, modelSuggestions: [] });
    }

    render() {
        const { currentVehicles } = this.state;

        if (this.state.revenda?.ativo === 0) {
            return (
                <div style={{ textAlign: "center" }}>
                    <h1>Site em manutenção.</h1>
                    <h3>Contate o suporte para mais detalhes (suporte@intermedioveiculos.com.br)</h3>
                </div>
            )
        }


        const settingsBanners = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 3000,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    },
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    },
                },
            ],
        };

        const settingsMobile = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2500,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        autoplay: true,
                    },
                },
                {
                    breakpoint: 1024,
                    settings: "unslick",
                },
            ],
        };;

        return (
            <div>
                <main>
                    <Header />
                    <section className="flex flex-col lg:flex-row justify-center items-center gap-10 mt-40 px-4 lg:px-0">
                        <div className="flex flex-col w-full lg:w-1/2 xl:w-1/3 space-y-10">
                            <div>
                                <h1 className="font-main font-bold text-5xl text-red-600">
                                    Veículos novos e seminovos <br /> com as melhores ofertas do mercado.
                                </h1>
                            </div>
                            <div className="flex flex-col justify-center items-center bg-gray-100 rounded-lg shadow-md space-y-8 p-8">
                                <div className="flex flex-col lg:flex-row gap-8 w-full justify-center">
                                    <div className="flex flex-col w-full lg:w-1/2 relative">
                                        <input
                                            onChange={this.handleInputChange}
                                            value={this.state.inputValue}
                                            name="marca"
                                            type="text"
                                            placeholder="Marca *"
                                            autoComplete="off"
                                            className="w-full border border-gray-300 rounded-md focus:ring-2 focus:ring-rose-500 focus:border-rose-500 focus:outline-none p-4"
                                        />
                                        {this.state.suggestions && this.state.suggestions.length > 0 && (
                                            <div className="absolute left-0 right-0 top-full mt-2 border border-gray-300 w-fit rounded-md bg-white z-10">
                                                {this.state.suggestions.map((suggestion, index) => (
                                                    <div
                                                        key={index}
                                                        className="p-2 cursor-pointer hover:bg-gray-200"
                                                        onClick={() => this.handleSuggestionClick(suggestion)}
                                                    >
                                                        {suggestion}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>

                                    <div className="flex flex-col w-full lg:w-1/2 relative">
                                        <input
                                            onChange={this.handleInputChangeModel}
                                            value={this.state.inputValueModelo}
                                            name="modelo"
                                            type="text"
                                            placeholder="Modelo *"
                                            autoComplete="off"
                                            className="w-full border border-gray-300 rounded-md focus:ring-2 focus:ring-rose-500 focus:border-rose-500 focus:outline-none p-4"
                                        />
                                        {this.state.modelSuggestions && this.state.modelSuggestions.length > 0 && (
                                            <div className="absolute left-0 right-0 top-full mt-2 border border-gray-300 w-fit rounded-md bg-white z-10">
                                                {this.state.modelSuggestions.slice(0, 10).map((suggestion, index) => (
                                                    <div
                                                        key={index}
                                                        className="p-2 cursor-pointer hover:bg-gray-200"
                                                        onClick={() => this.handleSuggestionClickModel(suggestion)}
                                                    >
                                                        {suggestion}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <button
                                    onClick={() => this.buscarPorMarcaModelo(this.state.inputValueModelo, this.state.inputValue)}
                                    className="bg-red-600 rounded-xl w-full py-4 font-main text-white"
                                >
                                    Buscar
                                </button>
                            </div>
                        </div>
                        <img src="/assets/capa.png" className="w-full lg:w-1/2 xl:w-1/3 max-w-xs lg:max-w-none min-w-[200px] sm:min-w-[300px]" />
                    </section>

                    <section>
                        <div className="block lg:hidden overflow-hidden w-full">
                            <Slider {...settingsMobile}>
                                <div className="flex flex-col items-center bg-gray-100 p-4 rounded-lg shadow-md max-w-xs mx-auto">
                                    <img src="./assets/location.png" alt="Location icon" className="w-auto" />
                                    <h2 className="font-semibold mt-4">Disponibilidade</h2>
                                    <p className="mt-2">
                                        O Oferta Autos em parceria com Lojas, Concessionárias e Auto Shoppings em todo o Brasil, busca sempre negociar com lojas e bancos para garantir a melhor condição de compra para você.
                                    </p>
                                </div>
                                <div className="flex flex-col items-center bg-gray-100 p-4 rounded-lg shadow-md max-w-xs mx-auto">
                                    <img src="./assets/financiamento.png" alt="Accounting icon" className="w-auto" />
                                    <h2 className="font-semibold mt-4">Financiamento</h2>
                                    <p className="mt-2">
                                        Em parcerias com os principais bancos as lojas cadastradas oferecem crédito para financiamento com taxas super competitivas e aprovação de crédito em poucos minutos.
                                    </p>
                                </div>
                                <div className="flex flex-col items-center bg-gray-100 p-4 rounded-lg shadow-md max-w-xs mx-auto">
                                    <img src="./assets/car.png" alt="Car icon" className="w-auto" />
                                    <h2 className="font-semibold mt-4">Estoque</h2>
                                    <p className="mt-2">
                                        Grande variedade de veículos novos, seminovos e blindados todas as marcas e modelos. Busque o veículo desejado e encontre as melhores ofertas do mercado.
                                    </p>
                                </div>
                                <div className="flex flex-col items-center bg-gray-100 p-4 rounded-lg shadow-md max-w-xs mx-auto">
                                    <img src="./assets/g135.png" alt="Wallet icon" className="w-auto" />
                                    <h2 className="font-semibold mt-4">Preços</h2>
                                    <p className="mt-2">
                                        Negociamos descontos, brindes e condições diferenciadas com nossas lojas parceiras, dando mais visibilidade a lojas que derem mais benefícios aos clientes. O melhor negócio está aqui.
                                    </p>
                                </div>
                            </Slider>
                        </div>

                        {/* Textos estáticos para tablets e desktops */}
                        <div className="hidden lg:flex lg:flex-row lg:justify-center lg:items-center lg:gap-8 xl:gap-20 p-4 lg:p-10 font-main mt-8">
                            <div className="flex flex-col items-center bg-gray-100 p-4 rounded-lg shadow-md max-w-xs">
                                <img src="./assets/location.png" alt="Location icon" className="w-auto" />
                                <h2 className="font-semibold mt-4">Disponibilidade</h2>
                                <p className="mt-2">
                                    O Oferta Autos em parceria com Lojas, Concessionárias e Auto Shoppings em todo o Brasil, busca sempre negociar com lojas e bancos para garantir a melhor condição de compra para você.
                                </p>
                            </div>
                            <div className="flex flex-col items-center bg-gray-100 p-4 rounded-lg shadow-md max-w-xs">
                                <img src="./assets/financiamento.png" alt="Accounting icon" className="w-auto" />
                                <h2 className="font-semibold mt-4">Financiamento</h2>
                                <p className="mt-2">
                                    Em parcerias com os principais bancos as lojas cadastradas oferecem crédito para financiamento com taxas super competitivas e aprovação de crédito em poucos minutos.
                                </p>
                            </div>
                            <div className="flex flex-col items-center bg-gray-100 p-4 rounded-lg shadow-md max-w-xs">
                                <img src="./assets/car.png" alt="Car icon" className="w-auto" />
                                <h2 className="font-semibold mt-4">Estoque</h2>
                                <p className="mt-2">
                                    Grande variedade de veículos novos, seminovos e blindados todas as marcas e modelos. Busque o veículo desejado e encontre as melhores ofertas do mercado.
                                </p>
                            </div>
                            <div className="flex flex-col items-center bg-gray-100 p-4 rounded-lg shadow-md max-w-xs">
                                <img src="./assets/g135.png" alt="Wallet icon" className="w-auto" />
                                <h2 className="font-semibold mt-4">Preços</h2>
                                <p className="mt-2">
                                    Negociamos descontos, brindes e condições diferenciadas com nossas lojas parceiras, dando mais visibilidade a lojas que derem mais benefícios aos clientes. O melhor negócio está aqui.
                                </p>
                            </div>
                        </div>
                    </section>

                    <section className="mt-4 carousel-container">
                        <Slider {...settingsBanners}>
                            <div>
                                <a href="https://veiculospremiumuberlandia.com.br/" target="_blank">
                                    <img src="./assets/banners/bannerAttra.jpeg" alt="Banner 1" className="w-full" />
                                </a>
                            </div>
                            <div>
                                <a href="https://automoveisuberlandia.com.br/#/" target="_blank">
                                    <img src="./assets/banners/bannerLMotors.jpeg" alt="Banner 2" className="w-full" />
                                </a>
                            </div>
                        </Slider>
                    </section>

                    <section>
                        <h2 className="font-main font-semibold text-2xl text-red-600 mt-10 mr-6 p-6">Veículos recentes</h2>
                        <div className="bg-white">
                            <div>
                                <VeiculosRecentes veiculos={currentVehicles} />
                            </div>
                        </div>
                    </section>

                    <section className="flex flex-col-reverse lg:flex-row items-center gap-12 lg:gap-16 px-4 lg:px-16 py-8">
                        <div className="w-full lg:w-1/2 flex-grow">
                            <h2 className="text-4xl font-bold tracking-tighter sm:text-5xl leading-tight mb-10">
                                Venha aprovar o seu financiamento conosco
                            </h2>
                            <p className="w-full mt-4 text-gray-500 md:text-xl lg:text-lg xl:text-xl dark:text-gray-400">
                                Descubra as melhores opções de financiamento para realizar seu sonho. <br />
                                Conte conosco para ajudar na sua jornada financeira.
                            </p>
                            <ul className="mt-6 grid grid-cols-1 sm:grid-cols-2 gap-6">
                                <li className="flex items-center gap-2">
                                    <div className="w-8 h-8 rounded-full bg-pink-500 flex items-center justify-center">
                                        <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="0.5" width="32" height="32" rx="16" fill="#E11D48" />
                                            <path d="M23.1654 11L13.9987 20.1667L9.83203 16" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </div>
                                    <span className="text-lg font-main">Financiamento simples com parcelas que cabem no seu bolso.</span>
                                </li>
                                <li className="flex items-center gap-2">
                                    <div className="w-8 h-8 rounded-full bg-pink-500 flex items-center justify-center">
                                        <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="0.5" width="32" height="32" rx="16" fill="#E11D48" />
                                            <path d="M23.1654 11L13.9987 20.1667L9.83203 16" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>

                                    </div>
                                    <span className="text-lg font-main">Simule seu crédito em minutos e veja as melhores opções.</span>
                                </li>
                                <li className="flex items-center gap-2">
                                    <div className="w-8 h-8 rounded-full bg-pink-500 flex items-center justify-center">
                                        <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="0.5" width="32" height="32" rx="16" fill="#E11D48" />
                                            <path d="M23.1654 11L13.9987 20.1667L9.83203 16" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </div>
                                    <span className="text-lg font-main">Taxas de juros justas para seu financiamento de veículos.</span>
                                </li>
                                <li className="flex items-center gap-2">
                                    <div className="w-8 h-8 rounded-full bg-pink-500 flex items-center justify-center">
                                        <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="0.5" width="32" height="32" rx="16" fill="#E11D48" />
                                            <path d="M23.1654 11L13.9987 20.1667L9.83203 16" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </div>
                                    <span className="text-lg font-main">Financiamento 100% online, sem sair de sua casa!</span>
                                </li>
                            </ul>
                            <Link
                                className="inline-flex items-center justify-center mt-8 h-10 w-48 rounded-md bg-red-600 px-8 text-sm font-medium text-white shadow-lg hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-pink-600 transform transition-transform duration-300 hover:scale-105"
                                to="/financiamento"
                            >
                                Financiamento
                            </Link>
                        </div>

                        <div className="w-46 lg:w-1/2">
                            <div className="aspect-w-16 aspect-h-9 overflow-hidden rounded-lg shadow-lg">
                                <img
                                    alt="Pessoas discutindo finanças"
                                    className="object-cover w-full h-full"
                                    src="./financas.png"
                                />
                            </div>
                        </div>
                    </section>

                    <section className="carousel-container">
                        <Slider {...settingsBanners}>
                            <div>
                                <a href="https://patos.ibizaveiculos.com.br/#/" target="_blank">
                                    <img src="./assets/banners/bannerIbiza.jpeg" alt="Banner 1" className="w-full" />
                                </a>
                            </div>
                            <div>
                                <a href="https://rsmotorsudi.com.br" target="_blank">
                                    <img src="./assets/banners/bannerRSMotors.jpeg" alt="Banner 2" className="w-full" />
                                </a>
                            </div>
                        </Slider>
                    </section>

                    <section>
                        <div className="flex flex-col justify-center items-center lg:flex-row bg-stone-800 p-10 rounded-md m-8 mt-16 gap-16">
                            <div className="text-center flex flex-col text-white font-main p-8">
                                <h2 className="text-xl md:text-3xl mb-2 font-bold">
                                    Encontre o carro que esteja mais
                                    próximo de você
                                </h2>
                                <p className="mb-6">
                                    Pesquise o nome da cidade e filtre os veículos que estão mais próximos de você
                                </p>
                                <form className="space-y-4" onSubmit={(e) => {
                                    e.preventDefault(); // Evita o reload da página
                                    this.handleVehicleByCity();
                                }}>
                                    <div className="relative w-full mt-4 flex flex-col md:items-center md:flex-row md:justify-center md:gap-4" style={{ maxWidth: '500px' }}>
                                        <input
                                            placeholder="CIDADE *"
                                            onChange={this.handleChange}
                                            name="buscaCidade"
                                            value={this.state.buscaCidade}
                                            className="border bg-gray-50 border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-rose-500 focus:border-rose-500 transition-all duration-300 p-3 text-lg w-full"
                                            required
                                        />
                                        <button
                                            type="submit"
                                            className="mt-2 mb-6 bg-red-600 text-sm text-white p-4 rounded-md hover:bg-red-700 transition duration-300 self-center"
                                        >
                                            PESQUISAR
                                        </button>
                                    </div>

                                    {this.state.enviado && (
                                        <blockquote className="mt-4 text-green-600">
                                            <p>
                                                <em>{this.state.msg_enviado}</em>
                                            </p>
                                        </blockquote>
                                    )}
                                </form>
                            </div>
                            <img src="./assets/Vector.png" alt="Logo veículo" />
                        </div>

                        <Footer />
                    </section>
                </main>
            </div >
        )

    }
}

function formatMoney(value) {
    var places = 2;
    var symbol = "R$ ";
    var thousand = ".";
    var decimal = ",";

    places = !isNaN(places = Math.abs(places)) ? places : 2;
    symbol = symbol !== undefined ? symbol : "$";
    thousand = thousand || ",";
    decimal = decimal || ".";

    var number = value,
        negative = number < 0 ? "-" : "",
        i = parseInt(number = Math.abs(+number || 0).toFixed(places), 10) + "",
        j = (i.length > 3 ? i.length % 3 : 0);

    return symbol + negative + (j ? i.substr(0, j) + thousand : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousand) + (places ? decimal + Math.abs(number - i).toFixed(places).slice(2) : "");
}

export default withNavigation(HomePage);