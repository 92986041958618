import Header from "../../components/Header";
import { Component } from "react";
import { ids_firebase } from "../../content-site";
import { firestore } from "../../services/firebase-config";
import { IoMdMenu } from "react-icons/io";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { Link, useLocation, useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";
import { FaSearch } from "react-icons/fa";
import LazyLoad from "react-lazyload";
import { colors } from "@material-ui/core";

function withNavigation(Component) {
    return function WrappedComponent(props) {
        const navigate = useNavigate();
        const location = useLocation();
        return <Component {...props} navigate={navigate} location={location} />;
    };
}

const estadosSiglas = {
    "acre": "AC",
    "alagoas": "AL",
    "amapa": "AP",
    "amazonas": "AM",
    "bahia": "BA",
    "ceara": "CE",
    "distritofederal": "DF",
    "espiritosanto": "ES",
    "goias": "GO",
    "maranhao": "MA",
    "matogrosso": "MT",
    "matogrossodosul": "MS",
    "minasgerais": "MG",
    "para": "PA",
    "paraiba": "PB",
    "parana": "PR",
    "pernambuco": "PE",
    "piaui": "PI",
    "riodejaneiro": "RJ",
    "riograndedonorte": "RN",
    "riograndedosul": "RS",
    "rondonia": "RO",
    "roraima": "RR",
    "santacatarina": "SC",
    "saopaulo": "SP",
    "sergipe": "SE",
    "tocantins": "TO"
};

const normalizarString = (texto) => {
    return texto
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/\s+/g, "");
};

class Comprar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            buscaCidade: 'todas',
            buscaEstado: 'todos',
            veiculos: [],
            veiculosComplete: [],
            countVeiculos: 0,
            instagramData: [],
            buscaVeiculo: '',
            tipoVeiculo: 'todos',
            revenda: {},
            bannerData: [],
            showMenu: window.innerWidth >= 768,
            showMoreBrands: false,
            minPrice: '',
            maxPrice: '',
            minYear: '',
            maxYear: '',
            marca: 'todas',
            filtroManual: false,
            filtroAutomatico: false,
            currentPage: 1,
            vehiclesPerPage: 5,
            currentVehicles: [],
            hasMore: true,
            loading: false,
            isLoading: true,
            coresSelecionadas: [],
            filtroBlindado: false,
            carroceriaSelecionada: [],
            combustiveisSelecionados: [],
            opcionaisSelecionados: [],
            todosOpcionais: [],
            mostrarOpcionais: false,
            allVehiclesLoaded: false
        }
        this.mounted = false;

        this.handleVehicleClick = this.handleVehicleClick.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleMarcaChange = this.handleMarcaChange.bind(this);
        this.handlePriceChange = this.handlePriceChange.bind(this);
        this.handleYearChange = this.handleYearChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleFiltroManualChange = this.handleFiltroManualChange.bind(this);
        this.handleFiltroAutomaticoChange = this.handleFiltroAutomaticoChange.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
        this.toggleMoreBrands = this.toggleMoreBrands.bind(this);
        this.handleFilterByBrand = this.handleFilterByBrand.bind(this);
        this.handleFilter = this.handleFilter.bind(this);
        this.handleVehicleByCity = this.handleVehicleByCity.bind(this);
        this.handleColorChange = this.handleColorChange.bind(this);
        this.handleOpcionaisChange = this.handleOpcionaisChange.bind(this);
        this.handleShowMoreVehicles = this.handleShowMoreVehicles.bind(this);
        this.handleFilterByBrand = this.handleFilterByBrand.bind(this);
        this.handleBlindadoChange = this.handleBlindadoChange.bind(this);
        this.handleCombustivelChange = this.handleCombustivelChange.bind(this);
        this.handleCarroceriaChange = this.handleCarroceriaChange.bind(this);
        this.toggleOpcionais = this.toggleOpcionais.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.logInterval = null;
    }

    handleVehicleClick(idVeiculo) {
        this.props.navigate(`/veiculos/${idVeiculo}`);
    }

    async componentDidMount() {
        this.setState({ isLoading: true });
    
        const revendaMap = {};
        const allVehicles = [];
        const initialVehicles = [];
        const allOpcionais = new Set();
    
        try {
            await Promise.all(Object.values(ids_firebase).map(async (obj) => {
                const revendaDoc = await firestore.collection("revenda").doc(obj.id).get();
                if (revendaDoc.exists) {
                    revendaMap[obj.href] = revendaDoc.data();
                } else {
                    revendaMap[obj.href] = { ativo: 0 };
                }
    
                const vehicleSnapshot = await firestore.collection("revenda").doc(obj.id)
                    .collection("veiculos")
                    .where("veremovido", "==", 0)
                    .where("vevendido", "==", 0)
                    .get();
    
                vehicleSnapshot.forEach(doc => {
                    const vehicle = doc.data();
                    vehicle.id = doc.id;
                    vehicle.href_revenda = obj.href;
                    if (vehicle["veexibir_site"] !== false) {
                        initialVehicles.push(vehicle);
    
                        if (vehicle.opcionais && Array.isArray(vehicle.opcionais)) {
                            vehicle.opcionais.forEach(opcional => {
                                allOpcionais.add(opcional.descricao);
                            });
                        }
                    }
                });
            }));
    
            const params = new URLSearchParams(this.props.location.search);
            const marca = params.get('marca');
            const modelo = params.get('modelo');
    
            let filteredVehicles;
            if (marca || modelo) {
                filteredVehicles = initialVehicles.filter(veiculo => {
                    const veiculoMarca = veiculo.vefipe_marca ? veiculo.vefipe_marca.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "") : "";
                    const veiculoModelo = veiculo.vefipe_name ? veiculo.vefipe_name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "") : "";
                    const matchesMarca = marca ? veiculoMarca.includes(marca.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")) : true;
                    const matchesModelo = modelo ? veiculoModelo.includes(modelo.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")) : true;
                    return matchesMarca && matchesModelo;
                });
            } else {
                filteredVehicles = initialVehicles;
            }
    
            this.setState({
                revenda: revendaMap,
                veiculos: filteredVehicles,
                veiculosComplete: initialVehicles,
                currentVehicles: filteredVehicles,
                isLoading: false,
                todosOpcionais: Array.from(allOpcionais)
            });
    
            this.loadRemainingVehicles();
        } catch (error) {
            console.error("Error fetching data: ", error);
            this.setState({ isLoading: false });
        }
    
        console.log(this.state.revenda);
    }


    componentDidUpdate(prevProps, prevState) {

        if (prevState.buscaCidade !== this.state.buscaCidade) {
            console.log('buscaCidade mudou:', this.state.buscaCidade);
        }

        if (prevState.veiculos.length !== this.state.veiculos.length) {
            //console.log("Atualização detectada: quantidade de veículos mudou.");

            if (this.state.veiculos.length === this.state.veiculosComplete.length) {
                console.log("Todos os veículos foram carregados.");

                if (this.state.allVehiclesLoaded) {
                    this.setState({ allVehiclesLoaded: true });
                    //console.log("Agora o ngc da true rapaziada")
                    console.log(this.state.veiculos)
                    //console.log("ENTREI AQUI")
                }
            }
        }
    }



    loadRemainingVehicles = async () => {
        const allVehicles = [...this.state.veiculosComplete];
    
        try {
            await Promise.all(Object.values(ids_firebase).map(async (obj) => {
                const vehicleSnapshot = await firestore.collection("revenda").doc(obj.id)
                    .collection("veiculos")
                    .where("veremovido", "==", 0)
                    .where("vevendido", "==", 0)
                    .get();
    
                vehicleSnapshot.forEach(doc => {
                    const vehicle = doc.data();
                    vehicle.id = doc.id;
                    vehicle.href_revenda = obj.href;
                    if (vehicle["veexibir_site"] !== false) {
                        allVehicles.push(vehicle);
                    }
                });
            }));
    
            const { search } = this.props.location;
            const params = new URLSearchParams(search);
            const marca = params.get('marca') || '';
            const modelo = params.get('modelo') || '';
    
            const filteredVehicles = allVehicles.filter(veiculo => {
                const matchesMarca = marca ? veiculo.vefipe_marca.toLowerCase().includes(marca.toLowerCase()) : true;
                const matchesModelo = modelo ? veiculo.vefipe_name.toLowerCase().includes(modelo.toLowerCase()) : true;
                return matchesMarca && matchesModelo;
            });
    
            this.setState({
                veiculos: filteredVehicles,
                veiculosComplete: filteredVehicles,
                allVehiclesLoaded: true,
            });
    
        } catch (error) {
            console.error("Error fetching data: ", error);
            this.setState({ isLoading: false });
        }
    };

    componentWillUnmount() {
        this.mounted = false;
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        }, () => {
            //console.log(`Updated ${name}:`, this.state[name]);
        });
    }


    handlePageChange = async (pageNumber) => {
        await this.fetchVeiculos(pageNumber);
        window.scrollTo(0, 0);
    }

    handleMarcaChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        }, this.handleFilter);
    }

    handlePriceChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        }, this.handleFilter);
    }

    handleYearChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name

        this.setState({
            [name]: value
        }, this.handleFilter)
    }

    handleBlindadoChange = (e) => {
        this.setState({
            filtroBlindado: e.target.checked
        }, this.handleFilter);
    }

    handleCarroceriaChange = (e) => {
        const { carroceriaSelecionada } = this.state;
        const carroceria = e.target.value;

        if (carroceria === 'todos') {
            if (carroceriaSelecionada.length === 0) {
                this.setState({
                    carroceriaSelecionada: ["sedan", "hatch", "suv", "pick-up", "passeio", "conversível", "antigo", "van/utilitario"]
                }, this.handleFilter)
            } else {
                this.setState({
                    carroceriaSelecionada: []
                }, this.handleFilter)
            }
        } else {
            if (carroceriaSelecionada.includes(carroceria)) {
                this.setState({
                    carroceriaSelecionada: carroceriaSelecionada.filter(c => c !== carroceria)
                }, this.handleFilter);
            } else {
                this.setState({
                    carroceriaSelecionada: [...carroceriaSelecionada, carroceria]
                }, this.handleFilter);
            }
        }
    };


    handleCombustivelChange = (e) => {
        const { combustiveisSelecionados } = this.state;
        const combustivel = e.target.value;

        if (combustivel === 'todos') {
            if (combustiveisSelecionados.length === 0) {
                this.setState({
                    combustiveisSelecionados: ["Flex", "Diesel", "Gasolina", "Álcool", "GNV", "Elétrico", "Híbrido"]
                }, this.handleFilter)
            } else {
                this.setState({
                    combustiveisSelecionados: []
                }, this.handleFilter)
            }
        } else {
            if (combustiveisSelecionados.includes(combustivel)) {
                this.setState({
                    combustiveisSelecionados: combustiveisSelecionados.filter(c => c !== combustivel)
                }, this.handleFilter);
            } else {
                this.setState({
                    combustiveisSelecionados: [...combustiveisSelecionados, combustivel]
                }, this.handleFilter);
            }
        }
    };


    handleSubmit = (event) => {
        event.preventDefault();

        const checkLoading = () => {
            // Verifica se todos os veículos foram carregados
            if (!this.state.allVehiclesLoaded) {
                //console.log("Carregando veículos, por favor, aguarde...");
                this.setState({ isLoading: true });

                // Checa novamente após 200ms
                setTimeout(checkLoading, 200);
            } else {
                // Se todos os veículos foram carregados, aplica os filtros
                this.setState({ isLoading: false }, () => {
                    this.applyFiltersOnSubmit();
                });
            }
        };

        checkLoading();
    };

    applyFiltersOnSubmit = () => {
        let filtro = this.state.buscaVeiculo
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, "");
        let tipoVeiculo = this.state.tipoVeiculo;

        let buscaVeiculo = this.state.veiculosComplete.filter((veiculo) => {
            let descricaoVeiculo = veiculo.vefipe_marca.toLowerCase() + " " + veiculo.vefipe_name.toLowerCase();

            // Verifica o tipo do veículo e aplica o filtro correspondente
            if (tipoVeiculo === 'novos') {
                return descricaoVeiculo.includes(filtro) && veiculo.vequilometragem <= 100;
            }
            if (tipoVeiculo === 'seminovos') {
                return descricaoVeiculo.includes(filtro) && veiculo.vequilometragem > 100;
            }

            return descricaoVeiculo.includes(filtro);
        });

        this.setState({
            currentVehicles: buscaVeiculo,
        });

    };

    handleVehicleByCity = (event) => {
        event.preventDefault();

        this.setState({ buscaCidade: this.state.buscaCidade }, () => {
            this.handleFilter();
        });
    };


    handleVehicleByEstado = (event) => {
        event.preventDefault();

        console.log(this.state.revenda)

        const estadoNormalizado = normalizarString(this.state.buscaEstado);
        const estadoSigla = estadosSiglas[estadoNormalizado];

        this.setState({ buscaEstado: estadoSigla || this.state.buscaEstado }, () => {
            this.handleFilter();
        });
    }



    handleClick(event) {
        this.setState({
            veiculos: this.state.veiculosComplete,
            buscaVeiculo: '',
            buscaCidade: '',
            tipoVeiculo: 'todos',
            minPrice: '',
            maxPrice: '',
            minYear: '',
            maxYear: '',
            marca: 'todas',
            filtroManual: '',
            filtroAutomatico: '',
            color: this.state.coresSelecionadas
        });
    }



    handleFiltroManualChange = (e) => {
        this.setState({ filtroManual: e.target.checked }, this.handleFilter);
    }

    handleFiltroAutomaticoChange = (e) => {
        this.setState({ filtroAutomatico: e.target.checked }, this.handleFilter);
    }

    handleColorChange = (e) => {
        const { coresSelecionadas } = this.state;
        const color = e.target.value;

        if (color === "todas") {
            if (coresSelecionadas.length === 0) {
                this.setState({
                    coresSelecionadas: ["Preto", "Prata", "Branco", "Vermelho", "Azul"]
                }, this.handleFilter);
            } else {
                this.setState({
                    coresSelecionadas: []
                }, this.handleFilter);
            }
        } else {
            if (coresSelecionadas.includes(color)) {
                this.setState({
                    coresSelecionadas: coresSelecionadas.filter(c => c !== color)
                }, this.handleFilter);
            } else {
                this.setState({
                    coresSelecionadas: [...coresSelecionadas, color]
                }, this.handleFilter);
            }
        }
    }

    handleOpcionaisChange(event) {
        const { value } = event.target;
        this.setState(prevState => {
            const { opcionaisSelecionados } = prevState;
            let updatedOpcionais;
            if (opcionaisSelecionados.includes(value)) {
                updatedOpcionais = opcionaisSelecionados.filter(op => op !== value);
                //console.log('Opcionais após remoção:', updatedOpcionais);
            } else {
                updatedOpcionais = [...opcionaisSelecionados, value];
                //console.log('Opcionais após adição:', updatedOpcionais);
            }
            return {
                opcionaisSelecionados: updatedOpcionais
            };
        }, () => {
            this.handleFilter(this.state.tipoVeiculo);
        });
    }



    toggleMenu() {
        this.setState(prevState => ({
            showMenu: !prevState.showMenu
        }));
    }

    toggleOpcionais = () => {
        this.setState(prevState => ({ mostrarOpcionais: !prevState.mostrarOpcionais }));
    };


    toggleMoreBrands() {
        this.setState(prevState => ({
            showMoreBrands: !prevState.showMoreBrands
        }));
    }

    handleFilterByBrand(marca) {
        this.setState({ marca: marca.toLowerCase() }, () => {
            this.props.navigate('/comprar?');
            this.handleFilter();

            //console.log("Marca filtrada:", marca);
        });
    }

    limparBusca() {
        this.props.navigate('/comprar');
        this.setState({
            buscaVeiculo: '',
            buscaCidade: '',
            buscaEstado: 'todos',
            tipoVeiculo: 'todos',
            minPrice: '',
            maxPrice: '',
            minYear: '',
            maxYear: '',
            marca: 'todas',
            filtroManual: false,
            filtroAutomatico: false,
            coresSelecionadas: [],
            filtroBlindado: false,
            carroceriaSelecionada: [],
            combustiveisSelecionados: [],
            opcionaisSelecionados: []
        });
    }

    buscarPorMarcaModelo(marca, modelo) {
        const { veiculosComplete } = this.state;
        const veiculosFiltrados = veiculosComplete.filter(veiculo => {
            return (
                (!marca || veiculo.vefipe_marca.toLowerCase().includes(marca.toLowerCase())) &&
                (!modelo || veiculo.vefipe_name.toLowerCase().includes(modelo.toLowerCase()))
            );
        });

        //console.log(`Veículos encontrados para marca "${marca}" e modelo "${modelo}": ${veiculosFiltrados.length}`);

        this.setState({
            currentVehicles: veiculosFiltrados,
            isLoading: false
        });
    }



    handleFilter = (tipoVeiculo) => {

        console.log(this.state.buscaEstado)


        const checkLoading = () => {
            if (!this.state.allVehiclesLoaded) {
                console.log("Carregando veículos, por favor, aguarde...");
                this.setState({ isLoading: true });
                setTimeout(checkLoading, 200);
            } else {
                this.setState({ isLoading: false }, () => {
                    this.applyFilters(tipoVeiculo);
                });
            }
        };

        checkLoading();
    };

    applyFilters = (tipoVeiculo) => {
        let filtro = this.state.buscaVeiculo.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
        let cidade = this.state.buscaCidade.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
        let estado = this.state.buscaEstado
        let minPrice = parseFloat(this.state.minPrice);
        let maxPrice = parseFloat(this.state.maxPrice);
        let minYear = parseInt(this.state.minYear);
        let maxYear = parseInt(this.state.maxYear);
        let marca = this.state.marca.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
        let filtroAutomatico = this.state.filtroAutomatico;
        let filtroManual = this.state.filtroManual;
        let veiculosComplete = this.state.veiculosComplete || [];
        let coresSelecionadas = this.state.coresSelecionadas || [];
        let filtroBlindado = this.state.filtroBlindado;
        let carroceriaSelecionada = this.state.carroceriaSelecionada;
        let combustiveisSelecionados = this.state.combustiveisSelecionados || [];
        let opcionaisSelecionados = this.state.opcionaisSelecionados || [];


        let veiculosFilter = veiculosComplete.filter(veiculo => {
            let descricaoVeiculo = ((veiculo.vefipe_marca ? veiculo.vefipe_marca.toLowerCase() : '') + " " +
                (veiculo.vefipe_name ? veiculo.vefipe_name.toLowerCase() : '')).normalize('NFD').replace(/[\u0300-\u036f]/g, "");

            let matchesCidade = cidade === 'todas' ||
                (cidade !== 'todas' && veiculo.href_revenda && this.state.revenda[veiculo.href_revenda] &&
                    this.state.revenda[veiculo.href_revenda].pecidade &&
                    this.state.revenda[veiculo.href_revenda].pecidade.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "") === cidade.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ""));

            let matchesEstado = estado === 'todos' ||
                (estado !== 'todos' && veiculo.href_revenda && this.state.revenda[veiculo.href_revenda] &&
                    this.state.revenda[veiculo.href_revenda].peuf &&
                    this.state.revenda[veiculo.href_revenda].peuf === estado);

            let matchesMarca = marca === 'todas' ||
                (veiculo.vefipe_marca && marca &&
                    veiculo.vefipe_marca.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "") === marca.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ""));

            let matchesPrice = true;
            if (!isNaN(minPrice)) {
                matchesPrice = veiculo.vevalor >= minPrice;
            }

            if (!isNaN(maxPrice)) {
                matchesPrice = matchesPrice && veiculo.vevalor <= maxPrice;
            }

            let matchesYear = true;
            if (!isNaN(minYear)) {
                matchesYear = veiculo.vefipe_ano_modelo >= minYear;
            }

            if (!isNaN(maxYear)) {
                matchesYear = matchesYear && veiculo.vefipe_ano_modelo <= maxYear;
            }

            let matchesCambio = (filtroAutomatico && veiculo.vecambio === "A") ||
                (filtroManual && veiculo.vecambio === "M") ||
                (!filtroManual && !filtroAutomatico);

            let matchesColor = coresSelecionadas.length === 0 ||
                coresSelecionadas.some(cor => cor.toLowerCase() === veiculo.vecor?.toLowerCase());

            let matchesBlindado = !filtroBlindado || (filtroBlindado && veiculo.veblindado === true);

            let matchesCarroceria = carroceriaSelecionada.length === 0 || carroceriaSelecionada.some(carroceria => carroceria.toLowerCase() === veiculo.vecarroceria?.toLowerCase());

            let matchesCombustivel = combustiveisSelecionados.length === 0 || combustiveisSelecionados.includes(veiculo.vefipe_combustivel);

            let matchesOpcionais = opcionaisSelecionados.length === 0 || (
                veiculo.opcionais && veiculo.opcionais.length > 0 && opcionaisSelecionados.every(opcSelecionado => {
                    const match = veiculo.opcionais.some(veiculoOpc => {
                        const descricaoNormalizada = veiculoOpc.descricao
                            .toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").trim();
                        const opcSelecionadoNormalizado = opcSelecionado
                            .toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").trim();

                        return descricaoNormalizada === opcSelecionadoNormalizado;
                    });

                    return match;
                })
            );

            if (tipoVeiculo === 'novos') {
                return descricaoVeiculo.includes(filtro) &&
                    veiculo.vequilometragem <= 100 &&
                    matchesPrice &&
                    matchesYear &&
                    matchesMarca &&
                    matchesCambio &&
                    matchesColor &&
                    matchesCombustivel &&
                    matchesCarroceria &&
                    matchesBlindado &&
                    matchesCidade &&
                    matchesEstado &&
                    matchesOpcionais;
            }
            if (tipoVeiculo === 'seminovos') {
                return descricaoVeiculo.includes(filtro) &&
                    veiculo.vequilometragem > 100 && veiculo.vequilometragem <= 10000 &&
                    matchesPrice &&
                    matchesYear &&
                    matchesMarca &&
                    matchesCambio &&
                    matchesColor &&
                    matchesCombustivel &&
                    matchesCarroceria &&
                    matchesBlindado &&
                    matchesCidade &&
                    matchesEstado &&
                    matchesOpcionais;
            }

            return descricaoVeiculo.includes(filtro) &&
                matchesPrice &&
                matchesYear &&
                matchesMarca &&
                matchesCambio &&
                matchesColor &&
                matchesCombustivel &&
                matchesCarroceria &&
                matchesBlindado &&
                matchesCidade &&
                matchesEstado &&
                matchesOpcionais;
        });

        let errorMessage = '';
        if (veiculosFilter.length === 0) {
            errorMessage = 'Nenhum veículo encontrado';
        }

        this.setState({
            veiculos: veiculosFilter,
            tipoVeiculo: tipoVeiculo,
            currentVehicles: veiculosFilter,
            errorMessage: errorMessage
        }, () => {
            console.log("Veículos após o filtro:", this.state.currentVehicles);
        });
        
    };

    renderLoading() {
        return (
            <div className="fixed inset-0 flex flex-col items-center justify-center z-50">
                <img className="w-64 h-auto" src='/assets/loading.gif' alt="Loading..." />
                <p className="text-red-500 font-semibold text-center text-xl">
                    Estamos preparando seus veículos...
                </p>
            </div>
        );
    }

    handleFilterChange = (ano, preco) => {
        this.setState({ ano, preco }, () => {
            this.handleFilter();
        });
    };


    handlePageChange = (pageNumber) => {
        this.setState({ currentPage: pageNumber });
        window.scrollTo(0, 0);
    }

    handleShowMoreVehicles = () => {
        this.setState((prevState) => ({
            vehiclesPerPage: prevState.vehiclesPerPage + 10
        }));
    }

    render() {
        const { veiculos, loading, hasMore } = this.state;

        return (
            <div className="overflow-hidden">
                <ul className="flex justify-center space-x-1 overflow-x-auto whitespace-nowrap rounded-md p-6">
                    {this.renderPageNumbers()}
                </ul>
                <div>
                    {veiculos.map(veiculo => (
                        <div key={veiculo.id}>{veiculo.nome}</div>
                    ))}
                </div>
                {loading && <div>Loading...</div>}
                {hasMore && !loading && (
                    <button onClick={() => this.handlePageChange(this.state.currentPage + 1)}>
                        Carregar Mais
                    </button>
                )}
            </div>
        );
    }

    render() {
        const {
            tipoVeiculo,
            showMenu,
            showMoreBrands,
            minPrice,
            maxPrice,
            minYear,
            maxYear,
            errorMessage,
            isLoading,
            todosOpcionais,
            opcionaisSelecionados,
            mostrarOpcionais,
            currentVehicles,
            allVehiclesLoaded
        } = this.state;

        if (this.state.revenda?.ativo === 0) {
            return (
                <div style={{ textAlign: "center" }}>
                    <h1>Site em manutenção.</h1>
                    <h3>Contate o suporte para mais detalhes (suporte@intermedioveiculos.com.br)</h3>
                </div>
            )
        }

        if (isLoading) {
            return this.renderLoading();
        }

        return (
            <main>
                <section>
                    <Header />
                    <section className="fixed top-16 left-0 w-full bg-white z-40 shadow-md pt-8 px-4 md:px-6 lg:px-8">
                        <ValidatorForm className="pb-4" id="contactForm" ref="form" onSubmit={this.handleSubmit}>
                            <div className={`flex flex-col md:flex-row items-center justify-between gap-2 mt-2 text-black`}>
                                <div className="flex flex-col md:flex-row items-start md:items-center gap-4 md:gap-2 md:space-y-0 md:justify-start items-center justify-center ms:flex-wrap ms:items-center">

                                    <button onClick={this.toggleMenu} className={`mt-20 md:mt-0 md:mr-5 px-4 py-2 rounded-md text-sm font-semibold bg-red-600 text-white transform transition-transform duration-300 hover:scale-105 ${showMenu ? 'mr-6' : ''}`}>
                                        <span>
                                            Busca Avançada
                                        </span>
                                    </button>

                                    <div className={`flex relative w-full max-w-xs md:max-w-md lg:max-w-lg mt-4 mb-0 md:mb-4 ${showMenu ? 'hidden' : 'block'} md:block`}>
                                        <input
                                            placeholder="Pesquisar"
                                            className="w-full h-10 bg-white text-gray-500 border border-gray-300 rounded-md pl-10 focus:border-rose-600 focus:ring-rose-600 focus:outline-none transition-all duration-300 hover:border-rose-500"
                                            onChange={this.handleChange}
                                            name="buscaVeiculo"
                                            value={this.state.buscaVeiculo}
                                        />
                                        <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500" />
                                    </div>

                                    <div className="flex space-x-2">
                                        <button
                                            className={`px-4 py-2 rounded-md text-sm font-semibold bg-red-600 hover:bg-red-900 text-white transition-all  ${showMenu ? 'hidden' : 'block'} md:block`}
                                            type="submit"
                                        >
                                            BUSCAR
                                        </button>
                                    </div>
                                </div>

                                <div className="hidden md:hidden lg:flex space-x-2">
                                    <button
                                        className={`px-4 py-2 rounded-md text-sm font-semibold ${tipoVeiculo === 'todos' ? 'bg-red-600 text-white' : 'bg-white text-black border'} transition-all ${tipoVeiculo === 'todos' ? '' : 'hover:bg-gray-200'} transform transition-transform duration-300 hover:scale-105`}
                                        onClick={() => this.handleFilter('todos')}
                                        type="button"
                                    >
                                        Todos
                                    </button>

                                    <button
                                        className={`px-4 py-2 rounded-md text-sm font-semibold ${tipoVeiculo === 'seminovos' ? 'bg-red-600 text-white' : 'bg-white text-black border'} transition-all ${tipoVeiculo === 'seminovos' ? '' : 'hover:bg-gray-200'} transform transition-transform duration-300 hover:scale-105`}
                                        onClick={() => this.handleFilter('seminovos')}
                                        type="button"
                                    >
                                        Seminovos
                                    </button>

                                    <button
                                        className={`px-4 py-2 rounded-md text-sm font-semibold ${tipoVeiculo === 'novos' ? 'bg-red-600 text-white' : 'bg-white text-black border'} transition-all ${tipoVeiculo === 'novos' ? '' : 'hover:bg-gray-200'} transform transition-transform duration-300 hover:scale-105`}
                                        onClick={() => this.handleFilter('novos')}
                                        type="button"
                                    >
                                        Zero KM
                                    </button>
                                </div>
                            </div>
                        </ValidatorForm>
                    </section>
                </section>

                <div className="relative flex">

                    <aside className={`fixed top-4  8 md:top-40 left-0 w-full h-full md:w-64 lg:w-96 xl:w-1/4 h-full p-6 bg-gray-200 border-r border-gray-300 overflow-y-auto transition-transform duration-300 ${showMenu ? 'translate-x-0' : '-translate-x-full'}`}
                        style={{
                            zIndex: 1000,
                            minHeight: 'calc(92vh - 5rem)',
                            height: 'calc(92vh - 5rem)',
                            marginTop: '1.5rem'
                        }} >

                        <section>
                            <div className="flex justify-end ">
                                <button onClick={this.toggleMenu} className="bg-slate-200 py-2 px-4 rounded-md">
                                    <FaLongArrowAltLeft size={24} />
                                </button>
                            </div>

                            <section>
                                <button
                                    className={`px-4 py-2 rounded-md text-sm font-semibold bg-white text-black border border-gray-300 hover:bg-gray-200 transition-all`}
                                    onClick={() => this.limparBusca()}
                                >
                                    LIMPAR FILTRO
                                </button>
                            </section>

                            <hr className="mt-5 mb-5 border-t border-gray-500 my-8" />

                            <h2 className="font-roboto text-lg font-semibold">Marcas mais procuradas</h2>
                            <div className="grid grid-cols-3 gap-4 justify-items-center mt-4">
                                <button className="p-2 m-0 border-2 border-slate-300 rounded-md shadow-md" onClick={() => this.handleFilterByBrand('chevrolet')}>
                                    <img src="./assets/logoMarcas/chevrolet.png" alt="Logo Chevrolet" className="block" />
                                </button>
                                <button className="p-2 m-0 border-2 border-slate-300 rounded-md shadow-md" onClick={() => this.handleFilterByBrand('ford')}>
                                    <img src="./assets/logoMarcas/ford.png" alt="Logo Ford" className="block" />
                                </button>
                                <button className="p-2 m-0 border-2 border-slate-300 rounded-md shadow-md" onClick={() => this.handleFilterByBrand('fiat')}>
                                    <img src="./assets/logoMarcas/fiat.png" alt="Logo Fiat" className="block" />
                                </button>
                                <button className="p-2 m-0 border-2 border-slate-300 rounded-md shadow-md" onClick={() => this.handleFilterByBrand('honda')}>
                                    <img src="./assets/logoMarcas/honda.png" alt="Logo Honda" className="block" />
                                </button>
                                <button className="p-2 m-0 border-2 border-slate-300 rounded-md shadow-md" onClick={() => this.handleFilterByBrand('renault')}>
                                    <img src="./assets/logoMarcas/renault.png" alt="Logo Renault" className="block" />
                                </button>
                                <button className="p-2 m-0 border-2 border-slate-300 rounded-md shadow-md" onClick={() => this.handleFilterByBrand('hyundai')}>
                                    <img src="./assets/logoMarcas/hyundai.png" alt="Logo Hyundai" className="block" />
                                </button>

                                {showMoreBrands && (
                                    <>
                                        <button className="p-2 m-0 border-2 border-slate-300 rounded-md shadow-md" onClick={() => this.handleFilterByBrand('jeep')}>
                                            <img src="./assets/logoMarcas/jeep.png" alt="Logo Jeep" className="block" />
                                        </button>
                                        <button className="p-2 m-0 border-2 border-slate-300 rounded-md shadow-md" onClick={() => this.handleFilterByBrand('mitsubishi')}>
                                            <img src="./assets/logoMarcas/mitsubishi.png" alt="Logo Mitsubishi" className="block" />
                                        </button>
                                        <button className="p-2 m-0 border-2 border-slate-300 rounded-md shadow-md" onClick={() => this.handleFilterByBrand('porsche')}>
                                            <img src="./assets/logoMarcas/porsche.png" alt="Logo Porsche" className="block" />
                                        </button>
                                        <button className="p-2 m-0 border-2 border-slate-300 rounded-md shadow-md" onClick={() => this.handleFilterByBrand('toyota')}>
                                            <img src="./assets/logoMarcas/toyota.png" alt="Logo Toyota" className="block" />
                                        </button>
                                        <button className="p-2 m-0 border-2 border-slate-300 rounded-md shadow-md" onClick={() => this.handleFilterByBrand('volkswagen')}>
                                            <img src="./assets/logoMarcas/volkswagen.png" alt="Logo Volkswagen" className="block" />
                                        </button>
                                        <button className="p2 m-0 border-2 border-slate-300 rounded-md shadow-md" onClick={() => this.handleFilterByBrand('bmw')}>
                                            <img src="./assets/logoMarcas/bmw.png" alt="Logo Bmw" className="block" />
                                        </button>
                                        <button className="p-2 m-0 border-2 border-slate-300 rounded-md shadow-md" onClick={() => this.handleFilterByBrand('audi')}>
                                            <img src="./assets/logoMarcas/audi.png" alt="Logo Audi" className="block" />
                                        </button>
                                        <button className="p-2 m-0 border-2 border-slate-300 rounded-md shadow-md" onClick={() => this.handleFilterByBrand('byd')}>
                                            <img src="./assets/logoMarcas/byd.png" alt="Logo BYD" className="block" />
                                        </button>
                                        <button className="p-2 m-0 border-2 border-slate-300 rounded-md shadow-md" onClick={() => this.handleFilterByBrand('caoa chery')}>
                                            <img src="./assets/logoMarcas/caoacherry.png" alt="Logo Caoa Chery" className="block" />
                                        </button>
                                        <button className="p-2 m-0 border-2 border-slate-300 rounded-md shadow-md" onClick={() => this.handleFilterByBrand('citroen')}>
                                            <img src="./assets/logoMarcas/citroen.png" alt="Logo Citroen" className="block" />
                                        </button>
                                        <button className="p-2 m-0 border-2 border-slate-300 rounded-md shadow-md" onClick={() => this.handleFilterByBrand('ferrari')}>
                                            <img src="./assets/logoMarcas/ferrari.png" alt="Logo Ferrari" className="block" />
                                        </button>
                                        <button className="p-2 m-0 border-2 border-slate-300 rounded-md shadow-md" onClick={() => this.handleFilterByBrand('lamborghini')}>
                                            <img src="./assets/logoMarcas/lamborghini.png" alt="Logo Lamborghini" className="block" />
                                        </button>
                                        <button className="p2 m-0 border-2 border-slate-300 rounded-md shadow-md" onClick={() => this.handleFilterByBrand('mercedes')}>
                                            <img src="./assets/logoMarcas/mercedes.png" alt="Logo Mercedes" className="block" />
                                        </button>
                                        <button className="p-2 m-0 border-2 border-slate-300 rounded-md shadow-md" onClick={() => this.handleFilterByBrand('mini')}>
                                            <img src="./assets/logoMarcas/mini.png" alt="Logo Mini" className="block" />
                                        </button>
                                        <button className="p-2 m-0 border-2 border-slate-300 rounded-md shadow-md" onClick={() => this.handleFilterByBrand('tesla')}>
                                            <img src="./assets/logoMarcas/tesla.png" alt="Logo Tesla" className="block" />
                                        </button>
                                        <button className="p-2 m-0 border-2 border-slate-300 rounded-md shadow-md" onClick={() => this.handleFilterByBrand('nissan')}>
                                            <img src="./assets/logoMarcas/nissan.png" alt="Logo Nissan" className="block" />
                                        </button>
                                        <button className="p-2 m-0 border-2 border-slate-300 rounded-md shadow-md" onClick={() => this.handleFilterByBrand('dodge')}>
                                            <img src="./assets/logoMarcas/dodge.png" alt="Logo Dodge" className="block" />
                                        </button>
                                        <button className="p-2 m-0 border-2 border-slate-300 rounded-md shadow-md" onClick={() => this.handleFilterByBrand('volvo')}>
                                            <img src="./assets/logoMarcas/volvo.png" alt="Logo Volvo" className="block" />
                                        </button>
                                    </>
                                )}
                            </div>
                            <div className="flex justify-end mt-4">
                                <button onClick={this.toggleMoreBrands} className="px-4 py-2 rounded-md text-sm font-semibold bg-white text-black border border-gray-300 hover:bg-gray-200 transition-all">
                                    {showMoreBrands ? 'Mostrar menos' : 'Mais marcas'}
                                </button>
                            </div>
                        </section>

                        <section>
                            <div className="flex lg:hidden justify-center items-center mt-10">
                                <div className="flex flex-row md:flex-col lg:hidden items-center justify-center gap-4 w-full">
                                    <button
                                        className={`px-4 py-2 rounded-md text-sm font-semibold ${tipoVeiculo === 'todos' ? 'bg-rose-600 text-white' : 'bg-white text-black border'} transition-all ${tipoVeiculo === 'todos' ? '' : 'hover:bg-gray-200'} transform transition-transform duration-300 hover:scale-105`}
                                        onClick={() => this.handleFilter('todos')}
                                        type="button"
                                    >
                                        Todos
                                    </button>

                                    <button
                                        className={`px-4 py-2 rounded-md text-sm font-semibold ${tipoVeiculo === 'seminovos' ? 'bg-rose-600 text-white' : 'bg-white text-black border'} transition-all ${tipoVeiculo === 'seminovos' ? '' : 'hover:bg-gray-200'} transform transition-transform duration-300 hover:scale-105`}
                                        onClick={() => this.handleFilter('seminovos')}
                                        type="button"
                                    >
                                        Seminovos
                                    </button>

                                    <button
                                        className={`px-4 py-2 rounded-md text-sm font-semibold ${tipoVeiculo === 'novos' ? 'bg-rose-600 text-white' : 'bg-white text-black border'} transition-all ${tipoVeiculo === 'novos' ? '' : 'hover:bg-gray-200'} transform transition-transform duration-300 hover:scale-105`}
                                        onClick={() => this.handleFilter('novos')}
                                        type="button"
                                    >
                                        Zero KM
                                    </button>
                                </div>
                            </div>
                        </section>

                        <section className="p-4 bg-white rounded-lg shadow-md mb-5 mt-5">
                            <h2 className="font-roboto text-xl font-semibold mb-4 border-b border-gray-300 pb-2">Pesquise o carro por cidade</h2>
                            <ValidatorForm className="space-y-4" onSubmit={this.handleVehicleByCity}>
                                <div className="mt-4 flex flex-col gap-4">
                                    <TextValidator
                                        placeholder="CIDADE *"
                                        onChange={this.handleChange}
                                        name="buscaCidade"
                                        value={this.state.buscaCidade}
                                        validators={['required']}
                                        errorMessages={['Preenchimento obrigatório']}
                                        className="w-full bg-white text-gray-700 border border-gray-300 rounded-md pl-3 pr-3 py-3 text-sm focus:border-rose-600 focus:ring-rose-600 focus:outline-none transition-all duration-300 hover:border-rose-500"
                                        InputProps={{
                                            style: { fontSize: '14px', width: '100%' },
                                        }}
                                    />

                                    <button
                                        type="submit"
                                        className="mt-2 mb-6 bg-rose-600 text-sm text-white p-4 rounded-md hover:bg-rose-700 transition duration-300 self-center"
                                    >
                                        PESQUISAR
                                    </button>
                                </div>

                                {this.state.enviado && (
                                    <blockquote className="mt-4 text-green-600">
                                        <p>
                                            <em>{this.state.msg_enviado}</em>
                                        </p>
                                    </blockquote>
                                )}
                            </ValidatorForm>
                        </section>

                        <section className="p-4 bg-white rounded-lg shadow-md mb-5 mt-5">
                            <h2 className="font-roboto text-xl font-semibold mb-4 border-b border-gray-300 pb-2">Pesquise o carro por estado</h2>
                            <ValidatorForm className="space-y-4" onSubmit={this.handleVehicleByEstado}>
                                <div className="mt-4 flex flex-col gap-4">
                                    <TextValidator
                                        placeholder="CIDADE *"
                                        onChange={this.handleChange}
                                        name="buscaEstado"
                                        value={this.state.buscaEstado}
                                        validators={['required']}
                                        errorMessages={['Preenchimento obrigatório']}
                                        className="w-full bg-white text-gray-700 border border-gray-300 rounded-md pl-3 pr-3 py-3 text-sm focus:border-rose-600 focus:ring-rose-600 focus:outline-none transition-all duration-300 hover:border-rose-500"
                                        InputProps={{
                                            style: { fontSize: '14px', width: '100%' },
                                        }}
                                    />

                                    <button
                                        type="submit"
                                        className="mt-2 mb-6 bg-rose-600 text-sm text-white p-4 rounded-md hover:bg-rose-700 transition duration-300 self-center"
                                    >
                                        PESQUISAR
                                    </button>
                                </div>

                                {this.state.enviado && (
                                    <blockquote className="mt-4 text-green-600">
                                        <p>
                                            <em>{this.state.msg_enviado}</em>
                                        </p>
                                    </blockquote>
                                )}
                            </ValidatorForm>
                        </section>

                        <hr className="mt-5 mb-5" />

                        <section className="p-4 bg-white rounded-lg shadow-md mb-6">
                            <h2 className="font-roboto text-xl font-semibold mb-4 border-b border-gray-300 pb-2">Ano</h2>
                            <div className="mt-4 flex flex-row gap-4">
                                <input
                                    placeholder="De"
                                    type="number"
                                    name="minYear"
                                    className="w-full h-12 bg-white text-gray-700 border border-gray-300 rounded-md pl-4 pr-2 focus:border-rose-600 focus:ring-rose-600 focus:outline-none transition-all duration-300 hover:border-rose-500"
                                    value={minYear}
                                    onChange={this.handleYearChange}
                                />

                                <input
                                    placeholder="Até"
                                    type="number"
                                    name="maxYear"
                                    className="w-full h-12 bg-white text-gray-700 border border-gray-300 rounded-md pl-4 pr-2 focus:border-rose-600 focus:ring-rose-600 focus:outline-none transition-all duration-300 hover:border-rose-500"
                                    value={maxYear}
                                    onChange={this.handleYearChange}
                                />
                            </div>
                        </section>

                        <hr className="mt-5 mb-5 border-t border-gray-500 my-8" />

                        <section className="p-4 bg-white rounded-lg shadow-md mb-6">
                            <h2 className="font-roboto text-xl font-semibold mb-4 border-b border-gray-300 pb-2">Preço</h2>
                            <div className="mt-4 flex flex-row gap-4">
                                <input
                                    placeholder="Valor mínimo"
                                    type="number"
                                    name="minPrice"
                                    className="w-full h-12 bg-white text-gray-700 border border-gray-300 rounded-md pl-4 pr-2 focus:border-rose-600 focus:ring-rose-600 focus:outline-none transition-all duration-300 hover:border-rose-500"
                                    value={minPrice}
                                    onChange={this.handlePriceChange}
                                />

                                <input
                                    placeholder="Valor máximo"
                                    type="number"
                                    name="maxPrice"
                                    className="w-full h-12 bg-white text-gray-700 border border-gray-300 rounded-md pl-4 pr-2 focus:border-rose-600 focus:ring-rose-600 focus:outline-none transition-all duration-300 hover:border-rose-500"
                                    value={maxPrice}
                                    onChange={this.handlePriceChange}
                                />
                            </div>
                        </section>

                        <hr className="mt-5 mb-5 border-t border-gray-500 my-8" />

                        <section className="p-4 bg-white rounded-lg shadow-md">
                            <h2 className="font-roboto text-xl font-semibold mb-4 border-b border-gray-300 pb-2">Câmbio</h2>
                            <div className="p-4 bg-white rounded-lg shadow-md">
                                <div className="flex flex-row md:flex-col gap-4">
                                    <label className="flex items-center text-lg">
                                        <input
                                            type="checkbox"
                                            className="mr-2 h-5 w-5 scale-125"
                                            checked={this.state.filtroManual}
                                            onChange={this.handleFiltroManualChange}
                                        />
                                        Manual
                                    </label>

                                    <label className="flex items-center text-lg">
                                        <input
                                            type="checkbox"
                                            className="mr-2 h-5 w-5 scale-125"
                                            checked={this.state.filtroAutomatico}
                                            onChange={this.handleFiltroAutomaticoChange}
                                        />
                                        Automático
                                    </label>
                                </div>
                            </div>
                        </section>


                        <hr className="mt-5 mb-5 border-t border-gray-500 my-8" />

                        <section className="p-4 bg-white rounded-lg shadow-md">
                            <h2 className="font-roboto text-xl font-semibold mb-4 border-b border-gray-300 pb-2">Cor</h2>
                            <div className="mt-2 flex flex-col gap-4">
                                <label className="flex items-center text-lg">
                                    <input
                                        type="checkbox"
                                        className="mr-2 h-5 w-5"
                                        value="todas"
                                        checked={this.state.coresSelecionadas.length === 0}
                                        onChange={this.handleColorChange}
                                    />
                                    Todas as cores
                                </label>
                                <label className="flex items-center text-lg">
                                    <input
                                        type="checkbox"
                                        className="mr-2 h-5 w-5"
                                        value="Preto"
                                        checked={this.state.coresSelecionadas.includes("Preto")}
                                        onChange={this.handleColorChange}
                                    />
                                    Preto
                                </label>
                                <label className="flex items-center text-lg">
                                    <input
                                        type="checkbox"
                                        className="mr-2 h-5 w-5"
                                        value="Prata"
                                        checked={this.state.coresSelecionadas.includes("Prata")}
                                        onChange={this.handleColorChange}
                                    />
                                    Prata
                                </label>
                                <label className="flex items-center text-lg">
                                    <input
                                        type="checkbox"
                                        className="mr-2 h-5 w-5"
                                        value="Branco"
                                        checked={this.state.coresSelecionadas.includes("Branco")}
                                        onChange={this.handleColorChange}
                                    />
                                    Branco
                                </label>
                                <label className="flex items-center text-lg">
                                    <input
                                        type="checkbox"
                                        className="mr-2 h-5 w-5"
                                        value="Vermelho"
                                        checked={this.state.coresSelecionadas.includes("Vermelho")}
                                        onChange={this.handleColorChange}
                                    />
                                    Vermelho
                                </label>
                                <label className="flex items-center text-lg">
                                    <input
                                        type="checkbox"
                                        className="mr-2 h-5 w-5"
                                        value="Azul"
                                        checked={this.state.coresSelecionadas.includes("Azul")}
                                        onChange={this.handleColorChange}
                                    />
                                    Azul
                                </label>
                            </div>
                        </section>

                        <hr className="mt-5 mb-5 border-t border-gray-500 my-8" />

                        <section className="p-4 bg-white rounded-lg shadow-md">
                            <h2 className="font-roboto text-xl font-semibold mb-4 border-b border-gray-300 pb-2">Blindagem</h2>
                            <div className="p-4 bg-white rounded-lg shadow-md">
                                <label className="flex items-center text-lg">
                                    <input
                                        type="checkbox"
                                        className="mr-2 h-5 w-5 scale-125"
                                        checked={this.state.filtroBlindado}
                                        onChange={this.handleBlindadoChange}
                                    />
                                    Apenas carros blindados
                                </label>
                            </div>
                        </section>


                        <hr className="mt-5 mb-5 border-t border-gray-500 my-8" />

                        <section className="p-4 bg-white rounded-lg shadow-md mb-6">
                            <h2 className="font-roboto text-xl font-semibold mb-4 border-b border-gray-300 pb-2">Carroceria</h2>
                            <div className="flex flex-col gap-4">
                                <label className="flex items-center text-lg">
                                    <input
                                        type="checkbox"
                                        className="mr-2 h-5 w-5 scale-125"
                                        value="todas"
                                        checked={this.state.carroceriaSelecionada.length === 0}
                                        onChange={this.handleCarroceriaChange}
                                    />
                                    Todas as carrocerias
                                </label>
                                <label className="flex items-center text-lg">
                                    <input
                                        type="checkbox"
                                        className="mr-2 h-5 w-5 scale-125"
                                        value="sedan"
                                        checked={this.state.carroceriaSelecionada.includes("sedan")}
                                        onChange={this.handleCarroceriaChange}
                                    />
                                    Sedan
                                </label>
                                <label className="flex items-center text-lg">
                                    <input
                                        type="checkbox"
                                        className="mr-2 h-5 w-5 scale-125"
                                        value="hatch"
                                        checked={this.state.carroceriaSelecionada.includes("hatch")}
                                        onChange={this.handleCarroceriaChange}
                                    />
                                    Hatch
                                </label>
                                <label className="flex items-center text-lg">
                                    <input
                                        type="checkbox"
                                        className="mr-2 h-5 w-5 scale-125"
                                        value="suv"
                                        checked={this.state.carroceriaSelecionada.includes("suv")}
                                        onChange={this.handleCarroceriaChange}
                                    />
                                    SUV
                                </label>
                                <label className="flex items-center text-lg">
                                    <input
                                        type="checkbox"
                                        className="mr-2 h-5 w-5 scale-125"
                                        value="pick-up"
                                        checked={this.state.carroceriaSelecionada.includes("pick-up")}
                                        onChange={this.handleCarroceriaChange}
                                    />
                                    Pick-up
                                </label>
                                <label className="flex items-center text-lg">
                                    <input
                                        type="checkbox"
                                        className="mr-2 h-5 w-5 scale-125"
                                        value="passeio"
                                        checked={this.state.carroceriaSelecionada.includes("passeio")}
                                        onChange={this.handleCarroceriaChange}
                                    />
                                    Passeio
                                </label>
                                <label className="flex items-center text-lg">
                                    <input
                                        type="checkbox"
                                        className="mr-2 h-5 w-5 scale-125"
                                        value="Conversível"
                                        checked={this.state.carroceriaSelecionada.includes("Conversível")}
                                        onChange={this.handleCarroceriaChange}
                                    />
                                    Conversível
                                </label>
                                <label className="flex items-center text-lg">
                                    <input
                                        type="checkbox"
                                        className="mr-2 h-5 w-5 scale-125"
                                        value="antigo"
                                        checked={this.state.carroceriaSelecionada.includes("antigo")}
                                        onChange={this.handleCarroceriaChange}
                                    />
                                    Antigo
                                </label>
                                <label className="flex items-center text-lg">
                                    <input
                                        type="checkbox"
                                        className="mr-2 h-5 w-5 scale-125"
                                        value="van/utilitario"
                                        checked={this.state.carroceriaSelecionada.includes("van/utilitario")}
                                        onChange={this.handleCarroceriaChange}
                                    />
                                    Van / Utilitário
                                </label>
                            </div>
                        </section>

                        <hr className="mt-5 mb-5 border-t border-gray-500 my-8" />

                        <section className="p-4 bg-white rounded-lg shadow-md mb-6">
                            <h2 className="font-roboto text-xl font-semibold mb-4 border-b border-gray-300 pb-2">Combustível</h2>
                            <div className="flex flex-col gap-4">
                                <label className="flex items-center text-lg">
                                    <input
                                        type="checkbox"
                                        className="mr-2 h-5 w-5 scale-125"
                                        value="todos"
                                        checked={this.state.combustiveisSelecionados.length === 0}
                                        onChange={this.handleCombustivelChange}
                                    />
                                    Todos os combustíveis
                                </label>
                                <label className="flex items-center text-lg">
                                    <input
                                        type="checkbox"
                                        className="mr-2 h-5 w-5 scale-125"
                                        value="Flex"
                                        checked={this.state.combustiveisSelecionados.includes("Flex")}
                                        onChange={this.handleCombustivelChange}
                                    />
                                    Flex
                                </label>
                                <label className="flex items-center text-lg">
                                    <input
                                        type="checkbox"
                                        className="mr-2 h-5 w-5 scale-125"
                                        value="Diesel"
                                        checked={this.state.combustiveisSelecionados.includes("Diesel")}
                                        onChange={this.handleCombustivelChange}
                                    />
                                    Diesel
                                </label>
                                <label className="flex items-center text-lg">
                                    <input
                                        type="checkbox"
                                        className="mr-2 h-5 w-5 scale-125"
                                        value="Gasolina"
                                        checked={this.state.combustiveisSelecionados.includes("Gasolina")}
                                        onChange={this.handleCombustivelChange}
                                    />
                                    Gasolina
                                </label>
                                <label className="flex items-center text-lg">
                                    <input
                                        type="checkbox"
                                        className="mr-2 h-5 w-5 scale-125"
                                        value="Álcool"
                                        checked={this.state.combustiveisSelecionados.includes("Álcool")}
                                        onChange={this.handleCombustivelChange}
                                    />
                                    Álcool
                                </label>
                                <label className="flex items-center text-lg">
                                    <input
                                        type="checkbox"
                                        className="mr-2 h-5 w-5 scale-125"
                                        value="GNV"
                                        checked={this.state.combustiveisSelecionados.includes("GNV")}
                                        onChange={this.handleCombustivelChange}
                                    />
                                    GNV
                                </label>
                                <label className="flex items-center text-lg">
                                    <input
                                        type="checkbox"
                                        className="mr-2 h-5 w-5 scale-125"
                                        value="Elétrico"
                                        checked={this.state.combustiveisSelecionados.includes("Elétrico")}
                                        onChange={this.handleCombustivelChange}
                                    />
                                    Elétrico
                                </label>
                                <label className="flex items-center text-lg">
                                    <input
                                        type="checkbox"
                                        className="mr-2 h-5 w-5 scale-125"
                                        value="Híbrido"
                                        checked={this.state.combustiveisSelecionados.includes("Híbrido")}
                                        onChange={this.handleCombustivelChange}
                                    />
                                    Híbrido
                                </label>
                            </div>
                        </section>

                        <hr className="mt-5 mb-5 border-t border-gray-500 my-8" />

                        <div className="p-4 bg-white rounded-lg shadow-md mb-6">
                            <h2 className="font-roboto text-xl font-semibold mb-4 border-b border-gray-300 pb-2">Opcionais</h2>
                            <section className="flex flex-col gap-4">
                                {todosOpcionais.length > 0 ? (
                                    <>
                                        {todosOpcionais.slice(0, mostrarOpcionais ? todosOpcionais.length : 5).map((opcional, index) => (
                                            <label key={index} className="flex items-center text-lg">
                                                <input
                                                    type="checkbox"
                                                    className="mr-2 h-5 w-5 scale-125"
                                                    value={opcional}
                                                    checked={opcionaisSelecionados.includes(opcional)}
                                                    onChange={this.handleOpcionaisChange}
                                                />
                                                {opcional}
                                            </label>
                                        ))}

                                        {todosOpcionais.length > 5 && (
                                            <button
                                                className="mt-2 text-rose-600 font-semibold"
                                                onClick={this.toggleOpcionais}
                                            >
                                                {mostrarOpcionais ? 'Mostrar menos' : 'Mostrar mais'}
                                            </button>
                                        )}
                                    </>
                                ) : (
                                    <div>
                                        {/*console.log(todosOpcionais)*/}
                                        <p className="text-lg">Nenhum opcional disponível</p>
                                    </div>
                                )}
                            </section>
                        </div>

                    </aside>
                </div>
                <section className="mt-80 md:mt-48 w-full h-full overflow-hidden">
                    <div className="relative lg:pr-[300px]">
                        <div className={`grid grid-cols-1 ${showMenu ? 'ml-32 md:ml-48 lg:ml-96 xl:ml-96 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-2' : 'md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3'} gap-2 transition-all duration-300`}>
                            {isLoading ? (

                                <div className="flex justify-center items-center col-span-3">
                                    <img src="../assets/loader.gif" alt="Carregando..." className="w-32 h-32" />
                                </div>
                            ) : errorMessage ? (

                                <div className="col-span-3 text-center text-red-500">
                                    {errorMessage}
                                </div>
                            ) : (

                                currentVehicles.length > 0 ? (
                                    currentVehicles.slice(0, this.state.vehiclesPerPage).map((veiculo) => (
                                        <div className="p-2 sm:p-2" key={veiculo.id}>
                                            <div className="flex flex-col items-center bg-white rounded-2xl p-2 h-auto shadow-md">
                                                <Link to={`/veiculos/${veiculo.id}`}>
                                                    <div className="flex justify-center">
                                                        {veiculo.imagens.length > 0 ? (
                                                            veiculo.imagens[0].fileThumb ? (
                                                                <LazyLoad height={200} offset={100}>
                                                                    <img className="rounded-md object-cover h-60" src={veiculo.imagens[0].fileThumb} alt="" />
                                                                </LazyLoad>
                                                            ) : (
                                                                <LazyLoad height={200} offset={100}>
                                                                    <img className="rounded-md object-cover h-60" src={veiculo.imagens[0].fileUrl} alt="" />
                                                                </LazyLoad>
                                                            )
                                                        ) : (
                                                            <LazyLoad height={200} offset={100}>
                                                                <img className="rounded-md object-cover h-40" src="/img/car_default.png" alt="imagem veículo" />
                                                            </LazyLoad>
                                                        )}
                                                    </div>
                                                </Link>
                                                <div className="text-center font-main font-bold mt-2 text-sm sm:text-base">
                                                    <Link className="block" to={`/veiculos/${veiculo.id}`}>
                                                        {veiculo.vefipe_marca}<br /> {veiculo.vefipe_name}
                                                    </Link>
                                                </div>
                                                <div>
                                                    <span>{veiculo.vefipe_ano_modelo}</span>
                                                </div>
                                                <div className="flex flex-col sm:flex-row justify-center gap-y-1 sm:gap-x-5 items-center w-full mt-1 font-semibold">
                                                    <span className="text-red-600 text-xl font-bold">{formatMoney(veiculo.vevalor)}</span>
                                                    <div className="bg-red-600 hover:bg-red-700 transform transition-transform duration-300 hover:scale-105 p-2 text-white rounded-2xl text-sm sm:text-base">
                                                        <Link to={`/veiculos/${veiculo.id}`}>Ver detalhes</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <div className="col-span-3 text-center">Nenhum veículo encontrado</div>
                                )
                            )}
                        </div>
                    </div>
                    <div className="flex justify-center items-center w-full">
                        <button onClick={this.handleShowMoreVehicles} className="text-white bg-red-600 hover:bg-red-700 py-4 px-10 mt-6 rounded-lg shadow-md text-center transition-all duration-300 font-main text-lg">
                            Carregar mais
                        </button>
                    </div>
                </section>


                <Footer />
            </main>
        )
    }
}

function formatMoney(value) {
    var places = 2;
    var symbol = "R$ ";
    var thousand = ".";
    var decimal = ",";

    places = !isNaN(places = Math.abs(places)) ? places : 2;
    symbol = symbol !== undefined ? symbol : "$";
    thousand = thousand || ",";
    decimal = decimal || ".";

    var number = value,
        negative = number < 0 ? "-" : "",
        i = parseInt(number = Math.abs(+number || 0).toFixed(places), 10) + "",
        j = (i.length > 3 ? i.length % 3 : 0);

    return symbol + negative + (j ? i.substr(0, j) + thousand : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousand) + (places ? decimal + Math.abs(number - i).toFixed(places).slice(2) : "");
}

export default withNavigation(Comprar);